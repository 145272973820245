
import LocationDetailPage from "../../components/LocationDetailPage"
import HoneymoonData, { HoneymoonBannerData } from "../../data/tanishkaData/HoneymoonData"


const FixedDepartureHoneymoon = () => {
  return (
    <>
      <LocationDetailPage BookingData={HoneymoonData} bookingBannerData={HoneymoonBannerData} />
    </>
  )
}

export default FixedDepartureHoneymoon