import LocationDetailPage from "../../components/LocationDetailPage"
import DomesticRajasthanData, { DomesticRajasthanBannerData } from "../../data/tanishkaData/DomesticRajasthanData"

const DomesticRajasthan = () => {
  return (
    <>
      <LocationDetailPage BookingData={DomesticRajasthanData} bookingBannerData={DomesticRajasthanBannerData} />
    </>
  )
}

export default DomesticRajasthan