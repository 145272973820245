
import ViewDetail from "../../components/ViewDetail"
import InternationalVietnamDetailBannerData, { InternationalVietnamTextData } from "../../data/tanishkaData/InternationalVietnamDetailData"

const InternationalVietnamDetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={InternationalVietnamDetailBannerData} aboutTourInfo={InternationalVietnamTextData} />
    </>
  )
}

export default InternationalVietnamDetail