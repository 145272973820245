

import people1 from '../../assest/taniska/heritage_img1.jpg'
import people2 from '../../assest/taniska/heritage_img2.jpg'
import people3 from '../../assest/taniska/heritage_img3.jpg'
import people4 from '../../assest/taniska/heritage_img4.jpg'
import people5 from '../../assest/taniska/heritage_img5.jpg'
import people6 from '../../assest/taniska/heritage_img6.jpg'



const TravelByCatogryData = [
    {
        id: 1,
        heading:'Heritage',
        imgSrc: people1,
    },
    {
        id: 2,
        heading:'Adventure',
        imgSrc: people2,
    },
    {
        id: 3,
        heading:'Art',
        imgSrc: people3,
    } ,
     {
        id: 4,
        heading:'Food and Cuisine',
        imgSrc: people4,
    },
     {
        id: 5,
        heading:'Honeymoon ',
        imgSrc: people5,
    }, {
        id: 6,
        heading:'Luxury ',
        imgSrc: people6,
    }
   
];
export default TravelByCatogryData