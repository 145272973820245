import LocationDetailPage from "../../components/LocationDetailPage"
import InternationalMaldivesData, { InternationalMaldivesBannerData } from "../../data/tanishkaData/InternationalMaldivesData"

const InternationalMaldives = () => {
  return (
    <>
      <LocationDetailPage BookingData={InternationalMaldivesData} bookingBannerData={InternationalMaldivesBannerData} />
    </>
  )
}

export default InternationalMaldives