import Footer from "../../common/TanishkaEnterprises/Footer/Footer"
import GalleryPhotos from "../../common/TanishkaEnterprises/Gallery/Gallery"
import Header from "../../common/TanishkaEnterprises/Header/Header"
import GallerData from "../../data/tanishkaData/GallerData"


const PhotoGallery = () => {
    return (
        <>
            <Header />

            <GalleryPhotos GalleryData={GallerData} title={"Photo Gallery"} />
            
            <Footer />

        </>
    )
}

export default PhotoGallery