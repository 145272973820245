
import ViewDetail from "../../components/ViewDetail"
import AdventureDetailPageBannerData, { AdventureDetailPageTextData } from "../../data/tanishkaData/AdventureDetailPageData"

const AdventureDetailPage = () => {
  return (
    <>
        <ViewDetail TourBannersData={AdventureDetailPageBannerData} aboutTourInfo={AdventureDetailPageTextData} />
    </>
  )
}

export default AdventureDetailPage
