import Footer from "../../common/TanishkaEnterprises/Footer/Footer"
import Header from "../../common/TanishkaEnterprises/Header/Header"
import polygon from '../../assest/taniska/domesticbanner.png'
import { Box, Button } from "@mui/material"
import { Link } from "react-router-dom"
import DomesticPackagesData from "../../data/tanishkaData/DomesticPackagesData"
import '../../styles/DomesticPackageStyle.css'

const DomesticPackages = () => {
    return (
        <>
            <Header />
            <Box className='Hos_Domestic_topdiv'>
                <img src={polygon} alt="img" />
                <div className="hosp_Domestichead">
                    <h1>Domestic Tour Packages</h1>
                </div>
            </Box>

            <div className="TravelOptions">
                <h1 className=" fw-bold py-4">Domestic Tour Packages</h1>
                <div className="TravelOptions_slide">
                    {DomesticPackagesData?.map((item, index) => (
                        <Link to={`/domestic_packages/${item.linkTag}`}>
                            <div key={item.id} className="TravelOptions_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >
            <Footer />


        </>
    )
}

export default DomesticPackages