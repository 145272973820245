

import LocationDetailPage from "../../components/LocationDetailPage"
import DomesticWestBengalData, { DomesticWestBengalBannerData } from "../../data/tanishkaData/DomesticWestBengalData"

const DomesticWestBengal = () => {
  return (
    <>
      <LocationDetailPage BookingData={DomesticWestBengalData} bookingBannerData={DomesticWestBengalBannerData} />
    </>
  )
}

export default DomesticWestBengal