

import kerala_1 from '../../assest/taniska/india/kerala/kerala_4.jpg'
import kerala_2 from '../../assest/taniska/india/kerala/kerala_5.jpg'



export const DomesticKeralaBannerData = [
    {
        bannerName: 'Kerala',
        bannerImage: kerala_2,
    },
];

const DomesticKeralaData = [
    {
        id: 1,
        tripName: 'Kerala Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Kerala, India',
        price: "₹20,671",
        imgSrc: kerala_1,
        linkTag:'domestic_packages/kerala/kerala_detail',
    }

];
export default DomesticKeralaData