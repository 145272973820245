import ViewDetail from "../../components/ViewDetail"
import HoneymoonDetailPageBannerData, { HoneymoonDetailPageTextData } from "../../data/tanishkaData/HoneymoonDetailPageData"

const HoneymoonDetailPage = () => {
  return (
    <>
        <ViewDetail TourBannersData={HoneymoonDetailPageBannerData} aboutTourInfo={HoneymoonDetailPageTextData} />
    </>
  )
}

export default HoneymoonDetailPage
