

import ViewDetail from "../../components/ViewDetail"
import DomesticLehLadakhDetailBannerData, { DomesticLehLadakhTextData } from "../../data/tanishkaData/DomesticLehLadakhDetailData"

const DomesticLehLadakhDetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={DomesticLehLadakhDetailBannerData} aboutTourInfo={DomesticLehLadakhTextData} />
    </>
  )
}

export default DomesticLehLadakhDetail