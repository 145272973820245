


import malaysiya from '../../assest/taniska/malaysiya.webp'
import Malaysia1 from '../../assest/taniska/Malaysia/Malaysia1.png'
import Malaysia2 from '../../assest/taniska/Malaysia/Malaysia2.png'
import Malaysia3 from '../../assest/taniska/Malaysia/Malaysia3.png'
import Malaysia4 from '../../assest/taniska/Malaysia/Malaysia4.jpg'





const InternationalMalaysiaDetailBannerData = [
    {
        id: 1,
        name: 'Malaysia Tour',
        src: Malaysia1,
    },
    {
        id: 2,
        name: 'Malaysia Tour',
        src: Malaysia2,
    },
    {
        id: 3,
        name: 'Malaysia Tour',
        src: Malaysia3,
    },
    {
        id: 4,
        name: 'Malaysia Tour',
        src: Malaysia4,
    }
    ,
    {
        id: 5,
        name: 'Malaysia Tour',
        src: malaysiya,
    }
  
   

];
export default InternationalMalaysiaDetailBannerData


export const InternationalMalaysiaTextData = [
    {
        detailText: "Also known as the ‘Land of Indigenous Malay’, Malaysia is a fusion of cultures that is a perfect place to visit in South Asia. With fantastic infrastructure, delicious food, exotic beaches, cultural heritage and ancient sculptures, the land of vibrant lights can lit your soul up and make you feel alive. Malaysia is a multi-ethnic, multilingual and multicultural country that states an ideal example of traditions as well as modernism. Malaysian is the official language of this country and Islam is its official religion. The second predominant religion in this country is Buddhism followed by Christianity, Hinduism and other Chinese religions. Islam is practised in among 60% of the inhabitants of Malaysia. Malaysia is rich in traditional values, performing arts, Malay music and traditional instruments that are influenced by India, China and Thailand. Malaysia’s cuisine reflects the multi-ethnicity as it has Chinese, Indian, Thai and Malay food. The chilly that is most widely used in the local cuisine does not spice up the food as much as we think. As this country is rich in traditions and cultures, there are several number of holidays as festivals keep arriving throughout the year. 31st August is celebrated as their Independence Day. Festivals such as Eid-Ul-Fitr, Eid-Ul-Adha, Chinese New Year, Diwali, Gawai and Kaamtan are celebrated vibrantly with joy in the country. Football is the most popular sport in this country and other outdoor games such as Hockey, Tennis and Squash are also liked by the inhabitants. Book your Malaysia Tour Packages way in advance if you want to save costs in the flight tickets and have extra fun in your Malaysia tourism."
    }

];