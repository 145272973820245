
import Jammukashmir1 from '../../assest/taniska/india/Jammukashmir/Jammukashmir1.jpg'
import Jammukashmir2 from '../../assest/taniska/india/Jammukashmir/Jammukashmir2.jpg'
import Jammukashmir3 from '../../assest/taniska/india/Jammukashmir/Jammukashmir3.jpg'
import Jammukashmir4 from '../../assest/taniska/india/Jammukashmir/Jammukashmir4.jpg'
import Jammukashmir5 from '../../assest/taniska/india/Jammukashmir/Jammukashmir5.jpg'





const DomesticJammukashmirDetailBannerData = [
    {
        id: 1,
        name: 'Jammu & kashmir Tour',
        src: Jammukashmir1,
    },
    {
        id: 2,
        name: 'Jammu & kashmir Tour',
        src: Jammukashmir2,
    },
    {
        id: 3,
        name: 'Jammu & kashmir Tour',
        src: Jammukashmir3,
    },
    {
        id: 4,
        name: 'Jammu & kashmir Tour',
        src: Jammukashmir4,
    },
    {
        id: 5,
        name: 'Jammu & kashmir Tour',
        src: Jammukashmir5,
    },
   
    
];
export default DomesticJammukashmirDetailBannerData


export const DomesticJammukashmirTextData = [
    {
      detailText: "The land that many dream to explore, Jammu & Kashmir is also known as the 'Crown of India' and 'Earth's Paradise'. Mughal Emperor Jehangir had once quoted 'If there is a heaven on Earth, it's here, it's here, it's here'. Jammu & Kashmir is the northernmost Indian province that is located in the western foothills of the Himalayas. Huge number of domestic and foreign tourists flock in this place as it's one of the top favorite places to visit in India. Every Indian dreams of visiting this place at least once in their life. Enchanting Chinar trees, beautiful apple orchards, majestic snow clad mountains, iconic lake houseboats, thick deodar forests, colorful tulips in spring, stunning Solang, adorable shikaras and snow heaven Gulmarg are this paradise's unique highlights that lures vacationers and visitors from all across the globe. A Jammu & Kashmir tourism takes you to a destination that offers you a way to get close with scenic nature, pristine lakes, magnificent mountains and beautiful valleys. Jammu & Kashmir, also known as the 'Little Switzerland' holds a rich princely history and throughout time, as history stands witness, the charming beauty of Kashmir has always been a topic that has been marveled upon. As truly said by someone, it is impossible to describe the beauty of Kashmir by penning it down into words or explaining through words. One can only feel it and fall in love with this northernmost province. So if you're also one of the all, fascinated by the thought of imagining yourself in this beautiful place then book our Jammu & Kashmir tour packages as we offer huge discounts."
    }
];