
import LocationDetailPage from "../../components/LocationDetailPage"
import InternationalEuropeData, { InternationalEuropeBannerData } from "../../data/tanishkaData/InternationalEuropeData"

const InternationalEurope = () => {
  return (
    <>
      <LocationDetailPage BookingData={InternationalEuropeData} bookingBannerData={InternationalEuropeBannerData} />
    </>
  )
}

export default InternationalEurope