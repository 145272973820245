
import LocationDetailPage from "../../components/LocationDetailPage"
import DomesticKeralaData, { DomesticKeralaBannerData } from "../../data/tanishkaData/DomesticKeralaData"

const DomesticKerala = () => {
  return (
    <>
      <LocationDetailPage BookingData={DomesticKeralaData} bookingBannerData={DomesticKeralaBannerData} />
    </>
  )
}

export default DomesticKerala