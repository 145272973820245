
import usa1 from '../../assest/taniska/usa/California.webp'
import usa2 from '../../assest/taniska/usa/San-Francisco.jpg'
import usa3 from '../../assest/taniska/usa/Vancouver.jpg'
import usa4 from '../../assest/taniska/usa.webp'



const InternationalUSADetailBannerData = [
    {
        id: 1,
        name: 'USA Tour',
        src: usa1,
    },
    {
        id: 2,
        name: 'USA Tour',
        src: usa2,
    },
    {
        id: 3,
        name: 'USA Tour',
        src: usa3,
    },
    {
        id: 4,
        name: 'USA Tour',
        src: usa4,
    },
    
];
export default InternationalUSADetailBannerData


export const InternationalUSATextData = [
    {
      detailText: "One of the largest countries in the world, an economic superpower, the United States of America has a large heart to boot; a heart that has embraced people from various ethnicities and cultures and has encouraged them to work together to achieve their American dream. USA holiday packages have such boundless diversity that travelers are at a loss as to where to begin. From some of the biggest cities in the world to charming little towns, from barren lands to thriving savannahs, from the native Indian culture to the heritage of the immigrants, from man made wonders to natural wonders, tour packages to the USA an all-encompassing experience. There are limitless options for nature lovers, history buffs, and adventure enthusiasts on USA tour packages from India that travelers keep coming back for more."
    }
];