





import LehLadakh1 from '../../assest/taniska/india/LehLadakh/LehLadakh1.jpg'
import LehLadakh2 from '../../assest/taniska/india/LehLadakh/LehLadakh2.jpg'
import LehLadakh3 from '../../assest/taniska/india/LehLadakh/LehLadakh3.jpg'
import LehLadakh4 from '../../assest/taniska/india/LehLadakh/LehLadakh4.jpg'
import LehLadakh5 from '../../assest/taniska/india/LehLadakh/LehLadakh5.jpg'






const DomesticLehLadakhDetailBannerData = [
    {
        id: 1,
        name: 'Leh Ladakh Tour',
        src: LehLadakh3,
    },
    {
        id: 2,
        name: 'Leh Ladakh Tour',
        src: LehLadakh2,
    },
    {
        id: 3,
        name: 'Leh Ladakh Tour',
        src: LehLadakh1,
    },
    {
        id: 4,
        name: 'Leh Ladakh Tour',
        src: LehLadakh5,
    },
    {
        id: 5,
        name: 'Leh Ladakh Tour',
        src: LehLadakh4,
    },

];
export default DomesticLehLadakhDetailBannerData


export const DomesticLehLadakhTextData = [
    {
        detailText: "If you're in Leh Ladakh, you can feel no less than being in heaven. Bounded by two of the mightiest mountain ranges of the world, the Great Himalayan Ranges and Karakoram, Leh Ladakh is mystical in all the spheres it covers. For those in confusion about the difference between these twin places, Leh-Ladakh, here's an information for you. The state of Jammu and Kashmir is divided into three provinces namely Jammu, Kashmir and Ladakh. The province of Ladakh is further divided into two districts namely Kargil and Leh. The district of Leh has a popular town named 'Leh' and it is a great tourist attraction as it is blessed with beautiful monasteries, vibrant markets, mountain passes and picturesque locations. Right from nature, geography, sceneries to the modest culture that it fosters, the super abundance of attractions to visit makes this city a heaven on Earth. This heaven opens doors to some of the most amazing, spectacular and breathtaking places to visit. Blessed by beauty of nature, Leh offers attractions that are irresistible to explore. A hotspot of history, religion, culture and adventure, Leh Ladakh woos everyone from the seekers of ancient mysticism to the most ardent adventure lovers. The unique characteristic of Leh Ladakh, which is a mix of Tibetan, Buddhism and Indian culture; will fascinate you in such a way that you will at least think once of cancelling your return tickets and staying there forever. So if you're already dreaming of being in Leh Ladakh, Don't! Rather, book Leh Ladakh Tour Packages with Sushant Travels that are one of the best service providers with cheap but quality tour packages."
    }
];