

import LocationDetailPage from "../../components/LocationDetailPage"
import InternationalBhutanData, { InternationalBhutanBannerData } from "../../data/tanishkaData/InternationalBhutanData"

const InternationalBhutan = () => {
  return (
    <>
      <LocationDetailPage BookingData={InternationalBhutanData} bookingBannerData={InternationalBhutanBannerData} />
    </>
  )
}

export default InternationalBhutan