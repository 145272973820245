import people1 from '../../assest/taniska/india/Uttarakhand/Uttarakhand1.jpg'
import people2 from '../../assest/taniska/india/LehLadakh/LehLadakh2.jpg'
import people3 from '../../assest/taniska/india/Delhi/Delhi6.webp'
import people4 from '../../assest/taniska/india/goa/goa1.png'
import people5 from '../../assest/taniska/india/Himachal/himachal_pradesh1.png'
import people6 from '../../assest/taniska/india/Jammukashmir/Jammukashmir1.jpg'
import people7 from '../../assest/taniska/india/kerala/kerala_1.png'
import people8 from '../../assest/taniska/india/Punjab/Punjab2.jpg'
import people9 from '../../assest/taniska/india/Rajasthan/Rajasthan1.jpg'
import people10 from '../../assest/taniska/india/Tamilnadu/Tamilnadu2.png'
import people11 from '../../assest/taniska/india/Andaman/Andaman1.png'
import people12 from '../../assest/taniska/india/WestBengal/WestBengal2.png'





const DomesticPackagesData = [
    {
        id: 1,
        heading: 'UTTARAKHAND',
        linkTag: 'uttarakhand',
        imgSrc: people1,
    },
    {
        id: 2,
        heading: 'LEH LADAKH ',
        linkTag: 'leh_ladakh',
        imgSrc: people2,
    },
    {
        id: 3,
        heading: 'DELHI',
        linkTag: 'delhi',
        imgSrc: people3,
    },
    {
        id: 4,
        heading: 'GOA',
        linkTag: 'goa',
        imgSrc: people4,
    },
    {
        id: 5,
        heading: 'HIMACHAL PRADESH ',
        linkTag: 'himachal_pradesh',
        imgSrc: people5,
    }
    , {
        id: 6,
        heading: 'JAMMU & KASHMIR ',
        linkTag: 'Jammu_kashmir',
        imgSrc: people6,
    },
    {
        id: 7,
        heading: 'KERALA ',
        linkTag: 'kerala',
        imgSrc: people7,
    }
    , {
        id: 8,
        heading: 'PUNJAB ',
        linkTag: 'punjab',
        imgSrc: people8,
    }
    , {
        id: 9,
        heading: 'RAJASTHAN ',
        linkTag: 'rajasthan',
        imgSrc: people9,
    }
    , {
        id: 10,
        heading: 'TAMIL NADU ',
        linkTag: 'tamil_nadu',
        imgSrc: people10,
    }
    , {
        id: 11,
        heading: 'ANDAMAN & NICOBAR ',
        linkTag: 'andaman',
        imgSrc: people11,
    }
    , {
        id: 12,
        heading: 'WEST BENGAL ',
        linkTag: 'west_bengal',
        imgSrc: people12,
    }


];
export default DomesticPackagesData