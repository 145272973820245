

import Tamilnadu1 from '../../assest/taniska/india/Tamilnadu/Tamilnadu1.png'
import Tamilnadu2 from '../../assest/taniska/india/Tamilnadu/Tamilnadu2.png'



export const DomesticTamilNaduBannerData = [
    {
        bannerName: 'Tamil Nadu',
        bannerImage: Tamilnadu1,
    },
];

const DomesticTamilNaduData = [
    {
        id: 1,
        tripName: 'Tamil Nadu Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Tamil Nadu, India',
        price: "₹20,671",
        imgSrc: Tamilnadu2,
        linkTag:'domestic_packages/tamil_nadu/tamil_nadu_detail',
    }

];
export default DomesticTamilNaduData