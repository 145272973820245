
import himachal_pradesh1 from '../../assest/taniska/india/Himachal/himachal_pradesh1.png'
import himachal_pradesh2 from '../../assest/taniska/india/Himachal/himachal_pradesh2.png'
import himachal_pradesh3 from '../../assest/taniska/india/Himachal/himachal_pradesh3.png'
import himachal_pradesh4 from '../../assest/taniska/india/Himachal/himachal_pradesh4.png'
import himachal_pradesh5 from '../../assest/taniska/india/Himachal/himachal_pradesh5.jpg'





const DomesticHimachalPradeshDetailBannerData = [
    {
        id: 1,
        name: 'Himachal Pradesh Tour',
        src: himachal_pradesh1,
    },
    {
        id: 2,
        name: 'Himachal Pradesh Tour',
        src: himachal_pradesh2,
    },
    {
        id: 3,
        name: 'Himachal Pradesh Tour',
        src: himachal_pradesh3,
    },
    {
        id: 4,
        name: 'Himachal Pradesh Tour',
        src: himachal_pradesh4,
    },
    {
        id: 5,
        name: 'Himachal Pradesh Tour',
        src: himachal_pradesh5,
    },
   
    
];
export default DomesticHimachalPradeshDetailBannerData


export const DomesticHimachalPradeshTextData = [
    {
      detailText: "Home to scenic mountain towns, Himachal Pradesh is a multi-cultural, multi-religious and multilingual state that is located in the Eastern Himalayas in the Northern part of India. Bordered by Jammu and Kashmir, Punjab, Haryana, Tibet and Uttarakhand. Himachal Pradesh is spread across beautiful valleys. Shimla is the capital of this state. Agriculture, horticulture, hydropower and tourism are the most significant contributions of the state's economy. Himachal Pradesh is considered as the least corrupted state in India. It is the second best-performing state in the country in terms of human development indicators. It is also home to a variety of Flora and Fauna and is considered one of the Richest Reservoirs of Biological Diversity in the World. This state is also home to some of the most popular National Parks such as Great Himalayan National Park and Pin Valley National Park. The tourism industry has flourished significantly in Himachal Pradesh as people from all over the world visit this state because of its top attractions. Popular hill stations such as Shimla, Manali, Kullu, Kasauli and Dalhousie are the most visited places in this state. Hindu pilgrimage sites such as Naina Devi Temple, Jwala Ji Temple, Chamunda Devi temple and Bhimakali temple are the most popular holy temples in this state. Outdoor activities such as ice skating, paragliding, skiing, river rafting, horse riding and trekking are among the most popular activities that attract a lot of tourists from all over the world."
    }
];