import './FooterStyle.css'
import Logo from "../../../assest/taniska/logtaniska.jpeg";
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { FaPhone } from "react-icons/fa6";

const Footer = () => {
   
    return (
        <>
            <Box className='Talk_news'>
                <div className="footertalk_maindiv">
                    <div className="heading_talk">
                        <h1 className="ps-2 d-flex align-items-center"><FaPhone className=' me-2'/> Call Now : +91 9871927829 </h1>
                    </div>

                </div>
            </Box>
            <footer className="newfooter">
                <div className="container">
                    <div className="footer_links">
                        <div className="row">
                            <div className="col-md-3">
                                <img src={Logo} alt="RestaurantIndia" className="footerlogo" />
                                {/* <div className="footer-sub-head">Moving Indian Tech Forward</div> */}
                                {/* <a href="/" target="_blank" className="redlnk">Become A Member</a> */}
                                <div className="footerlnk-head">Follow Us</div>
                                <ul className="soc_ul_footer">
                                    <li>
                                        <a href="/" target="_blank">
                                            <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/fb.png" alt="Facebook" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/" target="_blank">
                                            <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/in.png" alt="Instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/" target="_blank">
                                            <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/li.png" alt="LinkedIn" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/" target="_blank">
                                            <img src="https://www.indianretailer.com/restaurant/themes/menshealth/images/tw.png" alt="Twitter" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-3">
                                <div className="footerlnk-head">About Company</div>
                                <ul className="menu">
                                    <li><Link to="/about_us">About Us</Link></li>
                                    <li><Link to="/faq">FAQ</Link></li>
                                    <li><Link to="/Contact_us">Contact Us</Link></li>
                                    <li><Link to="/photo_gallery">Photo Gallery</Link></li>
                                    <li><Link to="/video_gallery">Video Gallery</Link></li>
                                    <li><Link to="/terms_condition">Terms & Condition</Link></li>
                                    <li><Link to="/travelogue">Travelogue</Link></li>
                                    {/* <li><Link to="/">Privacy Policy</Link></li> */}
                                </ul>
                            </div>

                            <div className="col-md-3">
                                <div className="footerlnk-head">Tours by Category</div>
                                <ul className="menu">
                                    <li><Link to="/fixed_departure/beach">Beach</Link></li>
                                    <li><Link to="/fixed_departure/wildLife">WildLife</Link></li>
                                    <li><Link to="/fixed_departure/adventure">Adventure</Link></li>
                                    <li><Link to="/fixed_departure/honeymoon">Honeymoon</Link></li>
                                </ul>
                            </div>

                            <div className="col-md-3">
                                <div className="footerlnk-head">Destinations</div>
                                <ul className="menu">
                                    {/* <li><Link to="/">Dubai</Link></li> */}
                                    <li><div className="footerlnk-head">Packages</div></li>
                                    <li><Link to="/domestic_packages">Domestic Packages</Link></li>
                                    <li><Link to="/international_packages">International Packages</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </footer>
            <div className="copyright">
                <div className="copy">
                    <p>Copyright © 2024 Powered By Abaris Softech.</p>
                </div>
            </div>


        </>
    )
}

export default Footer