
import people7 from '../../assest/taniska/the-best.jpeg'
import wildlife from '../../assest/taniska/wildlife.jpg'




export const WildLifeBannerData = [
    {
        bannerName: 'Wildlife',
        bannerImage: wildlife,
    },
];

const WildLifeData = [
    {
        id: 1,
        tripName: 'The Best of Thailand, Malaysia, and Singapore ',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: ' Pattaya, Bangkok, Kuala Lumpur, Singapore',
        price: "₹99,971",
        imgSrc: people7,
        linkTag:'fixed_departure/wildLife/wildLife_detail',
    },

];
export default WildLifeData