
import Andaman1 from '../../assest/taniska/india/Andaman/Andaman1.png'
import Andaman2 from '../../assest/taniska/india/Andaman/Andaman3.png'





export const DomesticAndamanBannerData = [
    {
        bannerName: 'Andaman and Nicobar',
        bannerImage: Andaman1,
    },
];

const DomesticAndamanData = [
    {
        id: 1,
        tripName: 'Andaman Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Andaman, India',
        price: "₹20,671",
        imgSrc: Andaman2,
        linkTag:'domestic_packages/andaman/andaman_detail',
    }

];
export default DomesticAndamanData