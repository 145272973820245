import React, { useState } from 'react'
import Logo from "../../../assest/taniska/logtaniska.jpeg";
import { Box, Button, Drawer } from '@mui/material';
import { MdOutlineMenu } from "react-icons/md";
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import './HeaderStyle.css'
import SideBar from '../sidebar/SideBar';


const Header = () => {
    const [navActive, setNavActive] = useState("#");

    const [openSide, setOpenSide] = useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpenSide(newOpen);
    };


    return (
        <>
            <Box className="tanishka_topHeader">
                <div className='tanishkadiv_mobile'>
                    <Button onClick={toggleDrawer(true)}><MdOutlineMenu className=' fs-1textwhite' /></Button>
                    <Drawer open={openSide} onClose={toggleDrawer(false)}>
                        <SideBar setOpenSide={setOpenSide} />
                    </Drawer>
                </div>
                <a href="/"
                    className={navActive === "/" ? "active" : ""}
                    onClick={() => setNavActive("/")}
                >
                    <img className="logo" src={Logo} alt='Logo' />
                    {/* <span className='ngo_compnyName'> NGO Trust</span> */}
                </a>

                <Box className='rightbox_taniska'>
                    <ul className="tanishka_liauto">
                        <li>
                            <a
                                onClick={() => setNavActive("/fixed_departure")}
                                className={navActive === "/fixed_departure" ? "active" : ""}
                                href="/fixed_departure"
                            >
                                Fixed Departure
                            </a>
                        </li>

                        <li className="">
                            <Dropdown>
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                Tours by category
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to="/fixed_departure/beach">Beach Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/fixed_departure/wildLife">WildLife Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/fixed_departure/adventure">Adventure Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/fixed_departure/honeymoon">Honeymoon Tour Packages</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>

                        {/* <li>
                            <Link
                                to="/visa"
                                onClick={() => setNavActive("/visa")}
                                className={navActive === "/visa" ? "active" : ""}
                            >
                                Visa
                            </Link>
                        </li> */}


                        <li className="">
                            <Dropdown>
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                Domestic Tours
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to="/domestic_packages/andaman">Andaman Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/domestic_packages/himachal_pradesh">Himachal Pradesh Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/domestic_packages/Jammu_kashmir">Jammu & kashmir Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/domestic_packages/kerala">Kerala Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/domestic_packages/punjab">Punjab Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/domestic_packages/rajasthan">Rajasthan Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/domestic_packages/delhi">Delhi Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/domestic_packages/tamil_nadu">Tamil Nadu Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/domestic_packages/uttarakhand">Uttarakhand Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/domestic_packages/goa">Goa Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/domestic_packages/west_bengal">West Bengal Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/domestic_packages/leh_ladakh">Leh Ladakh Tour Packages</Dropdown.Item>
                                
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>

                        <li className="">
                            <Dropdown>
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                International Tours
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to="/international_packages/indonesia">Indonesia Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/international_packages/singapore">Singapore Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/international_packages/thailand">Thailand Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/international_packages/dubai">Dubai Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/international_packages/malaysia">Malaysia Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/international_packages/vietnam">Vietnam Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/international_packages/maldives">Maldives Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/international_packages/europe">Europe Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/international_packages/sri_lanka">Sri Lanka Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/international_packages/nepal">Nepal Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/international_packages/bhutan">Bhutan Tour Packages</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/international_packages/USA">USA Tour Packages</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>

                        {/* <li>
                            <Link
                                onClick={() => setNavActive("/domestic_packages")}
                                className={navActive === "/domestic_packages" ? "active" : ""}
                                to="/domestic_packages"
                            >
                                Domestic
                            </Link>
                        </li>

                        <li>
                            <Link
                                onClick={() => setNavActive("/international_packages")}
                                className={navActive === "/international_packages" ? "active" : ""}
                                to="/international_packages"
                            >
                                International
                            </Link>
                        </li> */}
        
                        <li>
                            <Link
                                onClick={() => setNavActive("/travelogue")}
                                className={navActive === "/travelogue" ? "active" : ""}
                                to="/travelogue"
                            >
                                Travelogue
                            </Link>
                        </li>

                        {/* <li>
                            <Link
                                onClick={() => setNavActive("/offers")}
                                className={navActive === "/offers" ? "active" : ""}
                                to="/offers"
                            >
                                Offers
                            </Link>
                        </li> */}

                        <li>
                            <Link
                                onClick={() => setNavActive("/Contact_us")}
                                className={navActive === "/Contact_us" ? "active" : ""}
                                to="/Contact_us"
                            >
                                Contact
                            </Link>
                        </li>

                    </ul>
                </Box>
            </Box>
        </>
    )
}

export default Header