import LocationDetailPage from "../../components/LocationDetailPage"
import DomesticTamilNaduData, { DomesticTamilNaduBannerData } from "../../data/tanishkaData/DomesticTamilNaduData"

const DomesticTamilNadu = () => {
  return (
    <>
      <LocationDetailPage BookingData={DomesticTamilNaduData} bookingBannerData={DomesticTamilNaduBannerData} />
    </>
  )
}

export default DomesticTamilNadu