


import Uttarakhand1 from '../../assest/taniska/india/Uttarakhand/Uttarakhand1.jpg'
import Uttarakhand2 from '../../assest/taniska/india/Uttarakhand/Uttarakhand2.jpg'



export const DomesticUttarakhandBannerData = [
    {
        bannerName: 'Uttarakhand',
        bannerImage: Uttarakhand1,
    },
];

const DomesticUttarakhandData = [
    {
        id: 1,
        tripName: 'Uttarakhand Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Uttarakhand, India',
        price: "₹20,671",
        imgSrc: Uttarakhand2,
        linkTag:'domestic_packages/uttarakhand/uttarakhand_detail',
    }

];
export default DomesticUttarakhandData