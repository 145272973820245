 
 import LocationDetailPage from "../../components/LocationDetailPage"
import AllWorldData, { AllWorldBannerData } from "../../data/tanishkaData/AllWorldData"


const FixedDeparture = () => {
  return (
    <>
      <LocationDetailPage BookingData={AllWorldData} bookingBannerData={AllWorldBannerData} />
    </>
  )
}

export default FixedDeparture