import ViewDetail from "../../components/ViewDetail"
import DomesticPunjabDetailBannerData, { DomesticPunjabTextData } from "../../data/tanishkaData/DomesticPunjabDetailData"

const DomesticPunjabDetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={DomesticPunjabDetailBannerData} aboutTourInfo={DomesticPunjabTextData} />
    </>
  )
}

export default DomesticPunjabDetail