
import people1 from '../../assest/taniska/usa.webp'
import people2 from '../../assest/taniska/indonesia.jpg'
import people3 from '../../assest/taniska/interantional3.webp'
import people4 from '../../assest/taniska/interantional4.webp'
import people5 from '../../assest/taniska/interantional6.webp'
import people6 from '../../assest/taniska/interantional7.webp'
import people7 from '../../assest/taniska/interantional8.webp'
import people8 from '../../assest/taniska/Vietnam-Tour.jpg'
import people9 from '../../assest/taniska/maldives.jpg'
import people10 from '../../assest/taniska/europe.webp'
import people11 from '../../assest/taniska/Bhutan-Tour.jpg'
import people12 from '../../assest/taniska/Nepal-Tour.jpg'



const InternationalPackagesData = [
    {
        id: 1,
        heading:'USA',
        linkTag:'USA',
        imgSrc: people1,
    },
    {
        id: 2,
        heading:'INDONESIA',
        linkTag:'indonesia',
        imgSrc: people2,
    },
    {
        id: 3,
        heading:'MALAYSIA',
        linkTag:'malaysia',
        imgSrc: people3,
    } ,
     {
        id: 4,
        heading:'SINGAPORE',
        linkTag:'singapore',
        imgSrc: people4,
    },
     {
        id: 5,
        heading:'SRI LANKA ',
        linkTag:'sri_lanka',
        imgSrc: people5,
    }
    , {
        id: 6,
        heading:'THAILAND ',
        linkTag:'thailand',
        imgSrc: people6,
    }, 
    {
        id: 7,
        heading:'UNITED ARAB EMIRATES ',
        linkTag:'dubai',
        imgSrc: people7,
    }
    , {
        id: 8,
        heading:'VIETNAM ',
        linkTag:'vietnam',
        imgSrc: people8,
    }
    , {
        id: 9,
        heading:'Maldives ',
        linkTag:'maldives',
        imgSrc: people9,
    }
    , {
        id: 10,
        heading:'Europe ',
        linkTag:'europe',
        imgSrc: people10,
    },
    {
        id: 11,
        heading: 'Bhutan',
        linkTag:'bhutan',
        imgSrc: people11,
    },
    {
        id: 12,
        heading: 'Nepal',
        linkTag:'nepal',
        imgSrc: people12,
    },

    

   
];
export default InternationalPackagesData