import LocationDetailPage from "../../components/LocationDetailPage"
import InternationalSriLankaData, { InternationalSriLankaBannerData } from "../../data/tanishkaData/InternationalSriLankaData"

const InternationalSriLanka = () => {
  return (
    <>
      <LocationDetailPage BookingData={InternationalSriLankaData} bookingBannerData={InternationalSriLankaBannerData} />
    </>
  )
}

export default InternationalSriLanka