import videoo from '../../assest/taniska/demovidio.mp4'
import '../../styles/VideoGalleryStyle.css'
import { Box } from '@mui/material';
import Header from '../../common/TanishkaEnterprises/Header/Header';
import Footer from '../../common/TanishkaEnterprises/Footer/Footer';


const VideoGallery = () => {
    return (
        <>
            <Header />
            <Box>
                <h2 className='vediotag'>Video Gallery</h2>
                <div className='bgdiv'>
                    <div className="vidio-divbox">
                        <video
                            className="video-tagbtn"
                            playsInline
                            muted
                            autoPlay
                            controls
                            alt="All the devices"
                            src={videoo}

                        />
                    </div>
                    <div className="vidio-divbox">
                        <video
                            className="video-tagbtn"
                            playsInline
                            muted
                            autoPlay
                            controls
                            alt="All the devices"
                            src={videoo}

                        />
                    </div>
                    <div className="vidio-divbox">
                        <video
                            className="video-tagbtn"
                            playsInline
                            muted
                            autoPlay
                            controls
                            alt="All the devices"
                            src={videoo}

                        />
                    </div>
                    <div className="vidio-divbox">
                        <video
                            className="video-tagbtn"
                            playsInline
                            muted
                            autoPlay
                            controls
                            alt="All the devices"
                            src={videoo}

                        />
                    </div>
                    <div className="vidio-divbox">
                        <video
                            className="video-tagbtn"
                            playsInline
                            muted
                            autoPlay
                            controls
                            alt="All the devices"
                            src={videoo}

                        />
                    </div>
                    <div className="vidio-divbox">
                        <video
                            className="video-tagbtn"
                            playsInline
                            muted
                            autoPlay
                            controls
                            alt="All the devices"
                            src={videoo}

                        />
                    </div>

                    <div className="vidio-divbox">
                        <video
                            className="video-tagbtn"
                            playsInline
                            muted
                            autoPlay
                            controls
                            alt="All the devices"
                            src={videoo}

                        />
                    </div>
                    <div className="vidio-divbox">
                        <video
                            className="video-tagbtn"
                            playsInline
                            muted
                            autoPlay
                            controls
                            alt="All the devices"
                            src={videoo}

                        />
                    </div>
                    <div className="vidio-divbox">
                        <video
                            className="video-tagbtn"
                            playsInline
                            muted
                            autoPlay
                            controls
                            alt="All the devices"
                            src={videoo}

                        />
                    </div>
                    <div className="vidio-divbox">
                        <video
                            className="video-tagbtn"
                            playsInline
                            muted
                            autoPlay
                            controls
                            alt="All the devices"
                            src={videoo}

                        />
                    </div>
                    <div className="vidio-divbox">
                        <video
                            className="video-tagbtn"
                            playsInline
                            muted
                            autoPlay
                            controls
                            alt="All the devices"
                            src={videoo}

                        />
                    </div>
                    <div className="vidio-divbox">
                        <video
                            className="video-tagbtn"
                            playsInline
                            muted
                            autoPlay
                            controls
                            alt="All the devices"
                            src={videoo}

                        />
                    </div>
                    <div className="vidio-divbox">
                        <video
                            className="video-tagbtn"
                            playsInline
                            muted
                            autoPlay
                            controls
                            alt="All the devices"
                            src={videoo}

                        />
                    </div>
                </div>
            </Box>
            <Footer />

        </>
    )
}

export default VideoGallery
