import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";

import 'react-pro-sidebar/dist/css/styles.css';
import "./sideBar.css"
import { MdEventNote, MdOutlineQuestionAnswer } from "react-icons/md";
import { GiIndiaGate,GiWorld } from "react-icons/gi";
import { Link } from "react-router-dom";
import { GoHome } from "react-icons/go";
import { BiMessageSquareDetail } from "react-icons/bi";
import { GiSkills } from "react-icons/gi";
import { CgWebsite } from "react-icons/cg";
import { IoMdPhotos } from "react-icons/io";
import { FaUser } from "react-icons/fa6";
import { MdTour } from "react-icons/md";

const SideBar = () => {


  return (
    <>
      <div id="main-div">
        <ProSidebar
          style={{ height: "100%", position: "absolute" }}
        >
          <div className="sidebar-body-div">
            <SidebarContent className="main-div">
              <Menu className="sidebar-content-div"
                iconShape="circle"
              >
                <p className="Menus-p">MENUS</p>

                <MenuItem icon={<GoHome />}> Home <Link to={'/'} /></MenuItem>


                <SubMenu title="Domestic Tours" icon={<GiIndiaGate />}>
                  <MenuItem icon={<MdEventNote />}>Andaman Tour Packages<Link to="/domestic_packages/andaman" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Himachal Pradesh Tour Packages<Link to="/domestic_packages/himachal_pradesh" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Jammu & kashmir Tour Packages<Link to="/domestic_packages/Jammu_kashmir" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Kerala Tour Packages<Link to="/domestic_packages/kerala" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Punjab Tour Packages<Link to="/domestic_packages/punjab" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Rajasthan Tour Packages<Link to="/domestic_packages/rajasthan" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Delhi Tour Packages<Link to="/domestic_packages/delhi" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Tamil Nadu Tour Packages<Link to="/domestic_packages/tamil_nadu" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Uttarakhand Tour Packages<Link to="/domestic_packages/uttarakhand" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Goa Tour Packages<Link to="/domestic_packages/goa" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>West Bengal Tour Packages<Link to="/domestic_packages/west_bengal" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Leh Ladakh Tour Packages<Link to="/domestic_packages/leh_ladakh" /></MenuItem>

                </SubMenu>


                <SubMenu title="International Tours" icon={<GiWorld />}>
                  <MenuItem icon={<MdEventNote />}>Indonesia Tour Packages<Link to="/international_packages/indonesia" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Singapore Tour Packages<Link to="/international_packages/singapore" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Thailand Tour Packages<Link to="/international_packages/thailand" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Dubai Tour Packages<Link to="/international_packages/dubai" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Malaysia Tour Packages<Link to="/international_packages/malaysia" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Vietnam Tour Packages<Link to="/international_packages/vietnam" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Maldives Tour Packages<Link to="/international_packages/maldives" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Europe Tour Packages<Link to="/international_packages/europe" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Sri Lanka Tour Packages<Link to="/international_packages/sri_lanka" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Nepal Tour Packages<Link to="/international_packages/nepal" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Bhutan Tour Packages<Link to="/international_packages/bhutan" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>USA Tour Packages<Link to="/international_packages/USA" /></MenuItem>

                </SubMenu>

                <MenuItem icon={<MdTour />}> Fixed Departure <Link to={'/fixed_departure'} /></MenuItem>

                <SubMenu title="Tours by Category" icon={<GiSkills />}>
                  <MenuItem icon={<MdEventNote />}>Beach Tour Packages<Link to="/fixed_departure/beach" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>WildLife Tour Packages<Link to="/fixed_departure/wildLife" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Adventure Tour Packages<Link to="/fixed_departure/adventure" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Honeymoon Tour Packages<Link to="/fixed_departure/honeymoon" /></MenuItem>
                </SubMenu>


                <MenuItem icon={<FaUser />}> About Us <Link to={'/about_us'} /></MenuItem>

                <MenuItem icon={<IoMdPhotos />}>Photo Gallery <Link to={'/photo_gallery'} /></MenuItem>

                <MenuItem icon={<MdOutlineQuestionAnswer />}>FAQs<Link to={'/faq'} /></MenuItem>

                <MenuItem icon={<CgWebsite />}>Travelogue<Link to={'/travelogue'} /></MenuItem>


                <MenuItem icon={<BiMessageSquareDetail />}>Contact Us<Link to={'/Contact_us'} /></MenuItem>


              </Menu>
            </SidebarContent>
          </div>
        </ProSidebar>
      </div>
    </>
  );
};

export default SideBar;

