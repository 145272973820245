

import Rajasthan2 from '../../assest/taniska/india/Rajasthan/Rajasthan2.jpg'
import Rajasthan1 from '../../assest/taniska/india/Rajasthan/Rajasthan1.jpg'



export const DomesticRajasthanBannerData = [
    {
        bannerName: 'Rajasthan',
        bannerImage: Rajasthan2,
    },
];

const DomesticRajasthanData = [
    {
        id: 1,
        tripName: 'Rajasthan Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Rajasthan, India',
        price: "₹20,671",
        imgSrc: Rajasthan1,
        linkTag:'domestic_packages/rajasthan/rajasthan_detail',
    }

];
export default DomesticRajasthanData