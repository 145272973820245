
import ViewDetail from "../../components/ViewDetail"
import InternationalDubaiDetailBannerData, { InternationalDubaiTextData } from "../../data/tanishkaData/InternationalDubaiDetailData"

const InternationalDubaiDetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={InternationalDubaiDetailBannerData} aboutTourInfo={InternationalDubaiTextData} />
    </>
  )
}

export default InternationalDubaiDetail