
import LocationDetailPage from "../../components/LocationDetailPage"
import WildLifeData, { WildLifeBannerData } from "../../data/tanishkaData/WildLifeData"



const FixedDepartureWildLife = () => {
  return (
    <>
      <LocationDetailPage BookingData={WildLifeData} bookingBannerData={WildLifeBannerData} />
    </>
  )
}

export default FixedDepartureWildLife