

import LocationDetailPage from "../../components/LocationDetailPage"
import InternationalMalaysiaData, { InternationalMalaysiaBannerData } from "../../data/tanishkaData/InternationalMalaysiaData"

const InternationalMalaysia = () => {
  return (
    <>
      <LocationDetailPage BookingData={InternationalMalaysiaData} bookingBannerData={InternationalMalaysiaBannerData} />
    </>
  )
}

export default InternationalMalaysia