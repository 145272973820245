import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { useState, useEffect } from "react";
import { FaAngleUp } from "react-icons/fa";
// import TanishkaEnterprisesHome from "./page/TanishkaEnterprises/TanishkaEnterprisesHome";
import ContactUs from "./page/TanishkaEnterprises/ContactUs";
import Home from "./page/TanishkaEnterprises/Home";
import FAQPage from "./page/TanishkaEnterprises/FAQPage";
import VideoGallery from "./page/TanishkaEnterprises/VideoGallery";
import AboutUs from "./page/TanishkaEnterprises/AboutUs";
import PhotoGallery from "./page/TanishkaEnterprises/PhotoGallery";
import TermsOfUse from "./page/TanishkaEnterprises/TermsOfUse";
import Visa from "./page/TanishkaEnterprises/Visa";
import Travelogue from "./page/TanishkaEnterprises/Travelogue";
import FixedDeparture from "./page/TanishkaEnterprises/FixedDeparture";
// import ViewDetail from "./components/ViewDetail";
import DomesticPackages from "./page/TanishkaEnterprises/DomesticPackages";
import InternationalPackages from "./page/TanishkaEnterprises/InternationalPackages";
import ScrollToTop from "./components/ScrollToTop";
import FixedDepartureBeach from "./page/TanishkaEnterprises/FixedDepartureBeach";
import FixedDepartureWildLife from "./page/TanishkaEnterprises/FixedDepartureWildLife";
import FixedDepartureAdventure from "./page/TanishkaEnterprises/FixedDepartureAdventure";
import FixedDepartureHoneymoon from "./page/TanishkaEnterprises/FixedDepartureHoneymoon";
import BeachDetailPage from "./page/TanishkaEnterprises/BeachDetailPage";
import WildLifeDetailPage from "./page/TanishkaEnterprises/WildLifeDetailPage";
import AdventureDetailPage from "./page/TanishkaEnterprises/AdventureDetailPage";
import HoneymoonDetailPage from "./page/TanishkaEnterprises/HoneymoonDetailPage";
import FixedDepartureDetailPage from "./page/TanishkaEnterprises/FixedDepartureDetailPage";
import DomesticPackagesDetailPage from "./page/TanishkaEnterprises/DomesticPackagesDetailPage";
import InternationalPackagesDetailPage from "./page/TanishkaEnterprises/InternationalPackagesDetailPage";
import InternationalIndonesia from "./page/TanishkaEnterprises/InternationalIndonesia";
import InternationalIndonesiaDetail from "./page/TanishkaEnterprises/InternationalIndonesiaDetail";
import InternationalSingapore from "./page/TanishkaEnterprises/InternationalSingapore";
import InternationalSingaporeDetail from "./page/TanishkaEnterprises/InternationalSingaporeDetail";
import InternationalThailand from "./page/TanishkaEnterprises/InternationalThailand";
import InternationalThailandDetail from "./page/TanishkaEnterprises/InternationalThailandDetail";
import InternationalDubai from "./page/TanishkaEnterprises/InternationalDubai";
import InternationalDubaiDetail from "./page/TanishkaEnterprises/InternationalDubaiDetail";
import InternationalMalaysia from "./page/TanishkaEnterprises/InternationalMalaysia";
import InternationalMalaysiaDetail from "./page/TanishkaEnterprises/InternationalMalaysiaDetail";
import InternationalVietnam from "./page/TanishkaEnterprises/InternationalVietnam";
import InternationalVietnamDetail from "./page/TanishkaEnterprises/InternationalVietnamDetail";
import InternationalMaldives from "./page/TanishkaEnterprises/InternationalMaldives";
import InternationalMaldivesDetail from "./page/TanishkaEnterprises/InternationalMaldivesDetail";
import InternationalEurope from "./page/TanishkaEnterprises/InternationalEurope";
import InternationalEuropeDetail from "./page/TanishkaEnterprises/InternationalEuropeDetail";
import InternationalSriLanka from "./page/TanishkaEnterprises/InternationalSriLanka";
import InternationalSriLankaDetail from "./page/TanishkaEnterprises/InternationalSriLankaDetail";
import InternationalNepal from "./page/TanishkaEnterprises/InternationalNepal";
import InternationalNepalDetail from "./page/TanishkaEnterprises/InternationalNepalDetail";
import InternationalBhutan from "./page/TanishkaEnterprises/InternationalBhutan";
import InternationalBhutanDetail from "./page/TanishkaEnterprises/InternationalBhutanDetail";
import InternationalUSA from "./page/TanishkaEnterprises/InternationalUSA";
import InternationalUSADetail from "./page/TanishkaEnterprises/InternationalUSADetail";
import DomesticAndaman from "./page/TanishkaEnterprises/DomesticAndaman";
import DomesticAndamanDetail from "./page/TanishkaEnterprises/DomesticAndamanDetail";
import DomesticHimachalPradesh from "./page/TanishkaEnterprises/DomesticHimachalPradesh";
import DomesticHimachalPradeshDetail from "./page/TanishkaEnterprises/DomesticHimachalPradeshDetail";
import DomesticJammukashmir from "./page/TanishkaEnterprises/DomesticJammukashmir";
import DomesticJammukashmirDetail from "./page/TanishkaEnterprises/DomesticJammukashmirDetail";
import DomesticKerala from "./page/TanishkaEnterprises/DomesticKerala";
import DomesticKeralaDetail from "./page/TanishkaEnterprises/DomesticKeralaDetail";
import DomesticPunjab from "./page/TanishkaEnterprises/DomesticPunjab";
import DomesticPunjabDetail from "./page/TanishkaEnterprises/DomesticPunjabDetail";
import DomesticRajasthan from "./page/TanishkaEnterprises/DomesticRajasthan";
import DomesticRajasthanDetail from "./page/TanishkaEnterprises/DomesticRajasthanDetail";
import DomesticTamilNadu from "./page/TanishkaEnterprises/DomesticTamilNadu";
import DomesticTamilNaduDetail from "./page/TanishkaEnterprises/DomesticTamilNaduDetail";
import DomesticUttarakhand from "./page/TanishkaEnterprises/DomesticUttarakhand";
import DomesticUttarakhandDetail from "./page/TanishkaEnterprises/DomesticUttarakhandDetail";
import DomesticGoa from "./page/TanishkaEnterprises/DomesticGoa";
import DomesticGoaDetail from "./page/TanishkaEnterprises/DomesticGoaDetail";
import DomesticWestBengal from "./page/TanishkaEnterprises/DomesticWestBengal";
import DomesticWestBengalDetail from "./page/TanishkaEnterprises/DomesticWestBengalDetail";
import DomesticLehLadakh from "./page/TanishkaEnterprises/DomesticLehLadakh";
import DomesticLehLadakhDetail from "./page/TanishkaEnterprises/DomesticLehLadakhDetail";
import DomesticDelhi from "./page/TanishkaEnterprises/DomesticDelhi";
import DomesticDelhiDetail from "./page/TanishkaEnterprises/DomesticDelhiDetail";

function App() {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <BrowserRouter>
        <div className="top-to-btm">
          {showTopBtn && (
            <FaAngleUp
              className="icon-position icon-style "
              style={{ zIndex: 199 }}
              onClick={goToTop}
            />
          )}
        </div>

        <ScrollToTop />
        <Routes>

          {/* Tanishka Enterprises  */}

          <Route path='/' element={<Home />} />
          {/* <Route path='/Home2' element={<TanishkaEnterprisesHome />} /> */}
          <Route path='/Contact_us' element={<ContactUs />} />
          <Route path='/faq' element={<FAQPage />} />
          <Route path='/video_gallery' element={<VideoGallery />} />
          <Route path='/about_us' element={<AboutUs />} />
          <Route path='/photo_gallery' element={<PhotoGallery />} />
          <Route path='/terms_condition' element={<TermsOfUse />} />

          <Route path='/visa' element={<Visa />} />
          <Route path='/travelogue' element={<Travelogue />} />
          <Route path='/fixed_departure' element={<FixedDeparture />} />
          {/* <Route path='/view_detail' element={<ViewDetail />} /> */}
          <Route path='/domestic_packages' element={<DomesticPackages />} />
          <Route path='/international_packages' element={<InternationalPackages />} />
          <Route path='/fixed_departure/beach' element={<FixedDepartureBeach />} />
          <Route path='/fixed_departure/wildLife' element={<FixedDepartureWildLife />} />
          <Route path='/fixed_departure/adventure' element={<FixedDepartureAdventure />} />
          <Route path='/fixed_departure/honeymoon' element={<FixedDepartureHoneymoon />} />

          <Route path='/fixed_departure/fixed_departure_detail' element={<FixedDepartureDetailPage />} />
          <Route path='/fixed_departure/beach/beach_detail' element={<BeachDetailPage />} />
          <Route path='/fixed_departure/wildLife/wildLife_detail' element={<WildLifeDetailPage />} />
          <Route path='/fixed_departure/adventure/adventure_detail' element={<AdventureDetailPage />} />
          <Route path='/fixed_departure/honeymoon/honeymoon_detail' element={<HoneymoonDetailPage />} />

          <Route path='/domestic_packages/domestic_packages_detail' element={<DomesticPackagesDetailPage />} />
          <Route path='/international_packages/international_packages_detail' element={<InternationalPackagesDetailPage />} />

          <Route path='/international_packages/indonesia' element={<InternationalIndonesia />} />
          <Route path='/international_packages/indonesia/indonesia_detail' element={<InternationalIndonesiaDetail />} />

          <Route path='/international_packages/singapore' element={<InternationalSingapore />} />
          <Route path='/international_packages/singapore/singapore_detail' element={<InternationalSingaporeDetail />} />

          <Route path='/international_packages/thailand' element={<InternationalThailand />} />
          <Route path='/international_packages/thailand/thailand_detail' element={<InternationalThailandDetail />} />

          <Route path='/international_packages/dubai' element={<InternationalDubai />} />
          <Route path='/international_packages/dubai/dubai_detail' element={<InternationalDubaiDetail />} />

          <Route path='/international_packages/malaysia' element={<InternationalMalaysia />} />
          <Route path='/international_packages/malaysia/malaysia_detail' element={<InternationalMalaysiaDetail />} />

          <Route path='/international_packages/vietnam' element={<InternationalVietnam />} />
          <Route path='/international_packages/vietnam/vietnam_detail' element={<InternationalVietnamDetail />} />

          <Route path='/international_packages/maldives' element={<InternationalMaldives />} />
          <Route path='/international_packages/maldives/maldives_detail' element={<InternationalMaldivesDetail />} />

          <Route path='/international_packages/europe' element={<InternationalEurope />} />
          <Route path='/international_packages/europe/europe_detail' element={<InternationalEuropeDetail />} />

          <Route path='/international_packages/sri_lanka' element={<InternationalSriLanka />} />
          <Route path='/international_packages/sri_lanka/sri_lanka_detail' element={<InternationalSriLankaDetail />} />

          <Route path='/international_packages/nepal' element={<InternationalNepal />} />
          <Route path='/international_packages/nepal/nepal_detail' element={<InternationalNepalDetail />} />

          <Route path='/international_packages/bhutan' element={<InternationalBhutan />} />
          <Route path='/international_packages/bhutan/bhutan_detail' element={<InternationalBhutanDetail />} />

          <Route path='/international_packages/USA' element={<InternationalUSA />} />
          <Route path='/international_packages/USA/USA_detail' element={<InternationalUSADetail />} />

          <Route path='/domestic_packages/andaman' element={<DomesticAndaman />} />
          <Route path='/domestic_packages/andaman/andaman_detail' element={<DomesticAndamanDetail />} />

          <Route path='/domestic_packages/himachal_pradesh' element={<DomesticHimachalPradesh />} />
          <Route path='/domestic_packages/himachal_pradesh/himachal_pradesh_detail' element={<DomesticHimachalPradeshDetail />} />

          <Route path='/domestic_packages/Jammu_kashmir' element={<DomesticJammukashmir />} />
          <Route path='/domestic_packages/Jammu_kashmir/Jammu_kashmir_detail' element={<DomesticJammukashmirDetail />} />

          <Route path='/domestic_packages/kerala' element={<DomesticKerala />} />
          <Route path='/domestic_packages/kerala/kerala_detail' element={<DomesticKeralaDetail />} />

          <Route path='/domestic_packages/punjab' element={<DomesticPunjab />} />
          <Route path='/domestic_packages/punjab/punjab_detail' element={<DomesticPunjabDetail />} />

          <Route path='/domestic_packages/rajasthan' element={<DomesticRajasthan />} />
          <Route path='/domestic_packages/rajasthan/rajasthan_detail' element={<DomesticRajasthanDetail />} />

          <Route path='/domestic_packages/tamil_nadu' element={<DomesticTamilNadu />} />
          <Route path='/domestic_packages/tamil_nadu/tamil_nadu_detail' element={<DomesticTamilNaduDetail />} />

          <Route path='/domestic_packages/uttarakhand' element={<DomesticUttarakhand />} />
          <Route path='/domestic_packages/uttarakhand/uttarakhand_detail' element={<DomesticUttarakhandDetail />} />

          <Route path='/domestic_packages/goa' element={<DomesticGoa />} />
          <Route path='/domestic_packages/goa/goa_detail' element={<DomesticGoaDetail />} />

          <Route path='/domestic_packages/west_bengal' element={<DomesticWestBengal />} />
          <Route path='/domestic_packages/west_bengal/west_bengal_detail' element={<DomesticWestBengalDetail />} />

          <Route path='/domestic_packages/leh_ladakh' element={<DomesticLehLadakh />} />
          <Route path='/domestic_packages/leh_ladakh/leh_ladakh_detail' element={<DomesticLehLadakhDetail />} />

          <Route path='/domestic_packages/delhi' element={<DomesticDelhi />} />
          <Route path='/domestic_packages/delhi/delhi_detail' element={<DomesticDelhiDetail />} />

        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
