import Footer from "../../common/TanishkaEnterprises/Footer/Footer"
import Header from "../../common/TanishkaEnterprises/Header/Header"
import polygon from '../../assest/taniska/search-result-listing.jpg'
import { Box } from "@mui/material"
import '../../styles/AboutUsStyle.css'



const AboutUs = () => {

    return (
        <>
            <Header />

            <Box className='Aboutus_topdiv'>
                <img src={polygon} alt="img" />
                <div className="hosp_aboutushead">
                    <h1>About Us</h1>
                </div>
            </Box>

            <div className=" bg-light-subtle p-5">
                <div className="">
                    <p>To travel is to live, and so we believe in at Tanishka Tours, We complete 20 successful years in the travel industry specializing in offering travel services - both domestic and international, to a wide spectrum of satisfied and repeat guests, We are proud to be accredited with apex bodies and prestigious travel associations , our ethos - From the conception of Tanishka tours till date , remains the same "customer service with a smile" !</p>
                    <p>We believe that Success is measured by the number of satisfied customers and that is always been our endeavor.</p>
                    <p className=" fw-bold">Why us:</p>
                    <p>We provide customized travel services in all directions, from Premium luxury hotels to Holiday packages, all of which cover both Domestic and International destinations.</p>
                    <p>With an enthusiastic and an experienced team, We strive to do our best , and are committed to ensuring a dream travel to all our guests.</p>
                    <p className=" fw-bold">How are we different from an online portal ?</p>
                    <p>Our travel counselors are trained to understand the needs and desires of the guests and are updated with the latest travel trends .Based on individual needs, customized itineraries are weaved before turning into successful travel plans.</p>
                    <p>We offer best prices, at any time, at Tanishka Tours we believe that each travel plan has to be the best, From the most elementary to the most consequential aspects of travel are looked into with utmost care.</p>
                    <p>We create specialised travel packages like family holidays, honeymoons, group and series tours, meetings, incentives, conventions, technical visits, golfing tours, sporting trip's, special interest packages such as senior citizen tours and women only tours , There is a travel counselor assisting you through the entire process, from the time you make an initial enquiry with us till the end of your journey.</p>
                    <p className=" fw-bold">Exclusive attention is given to each and every one of our esteemed customers. Our endeavor is to make every travel, a special experience.</p>
                    <p>Learn what customers are saying about us, or, contact us today for planning your Corporate Tour or Family Holiday.</p>
                </div>
            </div>


            <Footer />

        </>
    )
}

export default AboutUs