import LocationDetailPage from "../../components/LocationDetailPage"
import AdventureData, { AdventureBannerData } from "../../data/tanishkaData/AdventureData"


const FixedDepartureAdventure = () => {
  return (
    <>
      <LocationDetailPage BookingData={AdventureData} bookingBannerData={AdventureBannerData} />
    </>
  )
}

export default FixedDepartureAdventure