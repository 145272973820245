import Footer from "../../common/TanishkaEnterprises/Footer/Footer"
import Header from "../../common/TanishkaEnterprises/Header/Header"
import polygon from '../../assest/taniska/search-result-listing.jpg'
import { Box, Button } from "@mui/material"
import '../../styles/VisaStyle.css'
import visaprocees1 from '../../assest/taniska/verified-visa.png'
import visaprocees2 from '../../assest/taniska/upload-document.png'
import visaprocees3 from '../../assest/taniska/online-shopping-visa.png'
import visaprocees4 from '../../assest/taniska/like-visa.png'




const Visa = () => {

    return (
        <>
            <Header />

            <Box className='Hospital_topdiv'>
                <img src={polygon} alt="img" />
                <div className="hosp_head">
                    <h1 className=" mt-5">We make Visa easy for you</h1>

                    {/* <div className=" input-group searchdiv">
                        <input type='text' name='FullName' placeholder='Search for a country'
                            className={`form-control`}
                        />
                        <Button>Search</Button>
                    </div> */}

                </div>
            </Box>

            <Box className='ClinicalExcellence'>
                <h1> Special tourist and business visa deals</h1>
                <h1>How It Works</h1>
                <div className="row align-items-center justify-content-center overflow-hidden">
                    <div className=" col-lg-3 col-12 Clinical_detail">
                        <div>
                            <p className="countno">1</p>
                            <img src={visaprocees1} alt="img" />
                            <p>Pay online via our secure payment gateway</p>

                        </div>
                    </div>

                    <div className=" col-lg-3 col-12 Clinical_detail">
                        <div>
                            <p className="countno">2</p>
                            <img src={visaprocees2} alt="img" />
                            <p>Upload your documents </p>

                        </div>
                    </div>
                    <div className=" col-lg-3 col-12 Clinical_detail">
                        <div>
                            <p className="countno">3</p>
                            <img src={visaprocees3} alt="img" />
                            <p>We verify and submit your documents </p>
                        </div>
                    </div>
                    <div className=" col-lg-3 col-12 Clinical_detail">
                        <div>
                            <p className="countno">4</p>
                            <img src={visaprocees4} alt="img" />
                            <p>Receive your Visa</p>
                        </div>
                    </div>


                </div>

            </Box>


            <Footer />

        </>
    )
}

export default Visa