
import malaysiyaimg from '../../assest/taniska/malaysiya.webp'
import Malaysia1 from '../../assest/taniska/Malaysia/Malaysia4.jpg'


export const InternationalMalaysiaBannerData = [
    {
        bannerName: 'Malaysia',
        bannerImage: malaysiyaimg,
    },
];

const InternationalMalaysiaData = [
    {
        id: 1,
        tripName: 'Malaysia Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Kuala Lumpur, Malaysia',
        price: "₹20,671",
        imgSrc: Malaysia1,
        linkTag:'international_packages/malaysia/malaysia_detail',
    },
   

];
export default InternationalMalaysiaData