import ViewDetail from "../../components/ViewDetail"
import BeachDetailPageBannerData, { BeachDetailPageTextData } from "../../data/tanishkaData/BeachDetailPageData"

const BeachDetailPage = () => {
  return (
    <>
        <ViewDetail TourBannersData={BeachDetailPageBannerData} aboutTourInfo={BeachDetailPageTextData} />
    </>
  )
}

export default BeachDetailPage