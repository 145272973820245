import { Box, Button } from "@mui/material"
import Header from "../../common/TanishkaEnterprises/Header/Header"
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import news1 from '../../assest/taniska/image_1.jpg'
import news2 from '../../assest/taniska/image_2.jpg'
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Footer from "../../common/TanishkaEnterprises/Footer/Footer";
import MainSlider from "../../common/TanishkaEnterprises/Slider/Slider";
import TopSliderData from "../../data/tanishkaData/TopSliderData";
import '../../styles/homeStyle.css'
import '../../styles/TanishkaEnterprisesHomeStyle.css'
import SecondSlider from "../../common/TanishkaEnterprises/SecondSlider/SecondSlider";
import PopularResortData from "../../data/tanishkaData/PopularResortData";
import WildlifeSafariData from "../../data/tanishkaData/WildlifeSafariData";
import imgtop from '../../assest/taniska/audience-1850.webp'
import imgge1 from '../../assest/taniska/1707995498tab1_0.png'
import imgge2 from '../../assest/taniska/1707995303tab1_1.png'
import imgge3 from '../../assest/taniska/1707994566tab1_2.jpg'
import imgge4 from '../../assest/taniska/1707994879tab1_3.jpg'
import styled from "styled-components";
import TopLocationData from "../../data/tanishkaData/TopLocationData";
import topIndiatour from '../../assest/taniska/top-india-tour_0.jpg'
import PackagesData from "../../data/tanishkaData/PackagesData";
import imgeadvisor from '../../assest/taniska/advisor.jpg'
import PopularToursIndiaData from "../../data/tanishkaData/PopularToursIndiaData";
import CurveSlider from "../../common/TanishkaEnterprises/CurveSlider/CurveSlider";
import InternationalTourPackageData from "../../data/tanishkaData/InternationalTourPackageData";
import BalkansTourpackageData from "../../data/tanishkaData/BalkansTourpackageData";
import excitingimg from '../../assest/taniska/exciting-tours.png'
import wildlifeimg from '../../assest/taniska/wildlife-swan-tours.jpg'
import TravelByCatogryData from "../../data/tanishkaData/TravelByCatogryData";
import happyClient1 from '../../assest/taniska/happy-client.jpg'
import happyClient2 from '../../assest/taniska/happy-client2.jpg'
import happyClient3 from '../../assest/taniska/happy-client3.jpg'
import happyClient4 from '../../assest/taniska/trusted-red.jpg'
import LocalTourData from "../../data/tanishkaData/LocalTourData";



const MyTitleMessage = styled.h1`
  position: absolute;
  width: 99.5%;
  top: 20rem;
  z-index: 1;
  margin-top: -125px;
  margin-left: 55px;
  text-align: start;
  strong {
    font-size: 1.25em;
    font-weight: bold;
    letter-spacing: 2px;

  }
  div {
    color: white;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4),0px 2px 5px rgba(0, 0, 0, 0.4);
    font-weight: 100;
    .main {
      font-size: 50px;
    }
    .main p{
      font-size: 24px;
       margin: 20px 20% 0 20%;

    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    top: 18rem;
    div {
      .main {
        font-size: 40px;
      }
    }
  }

  @media (max-width: 767px) {
    top: 16rem;
    width: 98%;
    div {
      .main {
        font-size: 18px;
      }
    }
  }
`;



const Home = () => {

    const data = [
        {
            id: 1,
            name: "Prince Kapoor",
            image: news2,
            experience: "Great Experience",
            review: 'We were overall happy with the arrangements at Baku, Azerbaijan. They gave us options to choose from for hotels to stay, with a good deal. The car arranged for sightseeing was good. Also we were concerned about safety & risk involved in the scenario of Israel - Hamas war. They were reassuring & patient in dealing us. Harshali especially was very courteous & amazing during the whole booking process. Will definitely go again with cluster.'
        },
        {
            id: 2,
            name: "Prince Kapoor",
            image: news2,
            experience: "Great Experience",
            review: 'We were overall happy with the arrangements at Baku, Azerbaijan. They gave us options to choose from for hotels to stay, with a good deal. The car arranged for sightseeing was good. Also we were concerned about safety & risk involved in the scenario of Israel - Hamas war. They were reassuring & patient in dealing us. Harshali especially was very courteous & amazing during the whole booking process. Will definitely go again with cluster.'
        },
        {
            id: 3,
            name: "Prince Kapoor",
            image: news2,
            experience: "Great Experience",
            review: 'We were overall happy with the arrangements at Baku, Azerbaijan. They gave us options to choose from for hotels to stay, with a good deal. The car arranged for sightseeing was good. Also we were concerned about safety & risk involved in the scenario of Israel - Hamas war. They were reassuring & patient in dealing us. Harshali especially was very courteous & amazing during the whole booking process. Will definitely go again with cluster.'
        },
        {
            id: 4,
            name: "Prince Kapoor",
            image: news2,
            experience: "Great Experience",
            review: 'We were overall happy with the arrangements at Baku, Azerbaijan. They gave us options to choose from for hotels to stay, with a good deal. The car arranged for sightseeing was good. Also we were concerned about safety & risk involved in the scenario of Israel - Hamas war. They were reassuring & patient in dealing us. Harshali especially was very courteous & amazing during the whole booking process. Will definitely go again with cluster.'
        },
        {
            id: 5,
            name: "Prince Kapoor",
            image: news2,
            experience: "Great Experience",
            review: 'We were overall happy with the arrangements at Baku, Azerbaijan. They gave us options to choose from for hotels to stay, with a good deal. The car arranged for sightseeing was good. Also we were concerned about safety & risk involved in the scenario of Israel - Hamas war. They were reassuring & patient in dealing us. Harshali especially was very courteous & amazing during the whole booking process. Will definitely go again with cluster.'
        },
        {
            id: 6,
            name: "Prince Kapoor",
            image: news2,
            experience: "Great Experience",
            review: 'We were overall happy with the arrangements at Baku, Azerbaijan. They gave us options to choose from for hotels to stay, with a good deal. The car arranged for sightseeing was good. Also we were concerned about safety & risk involved in the scenario of Israel - Hamas war. They were reassuring & patient in dealing us. Harshali especially was very courteous & amazing during the whole booking process. Will definitely go again with cluster.'
        }
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        responsive: [
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 9999, // A large value to ensure the last breakpoint always applies
                settings: {
                    slidesToShow: 6,
                },
            }
        ],
    };

    const handleContactClick = (phoneNumber) => {
        window.location.href = `whatsapp://send?phone=${encodeURIComponent(phoneNumber)}`;
    };

    return (
        <>
            <Header />

            <Box id="BoxId">
                <Swiper
                    centeredSlides={true}
                    spaceBetween={0}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    autoplay={{
                        delay: 1500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Pagination]}
                    className=""
                >
                    {TopLocationData.map(({ id, imgSrc, name }) => {
                        return (
                            <SwiperSlide key={id} className="">
                                <div className="custom-img">
                                    <img src={imgSrc} alt="img" />
                                    <MyTitleMessage>
                                        <div className="titleMessage">
                                            <div className="heading">
                                                <div className="main mb-3">
                                                    <span>
                                                        <strong style={{ textTransform: 'uppercase' }}>{name}</strong>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                    </MyTitleMessage>
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Box>

            <Box className='athospital_welcomeDiv'>
                <Box className="athospitalhome row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img src={topIndiatour} alt="img" />
                    </div>

                    <div className="textdiv col-lg-6 col-12">
                        <h6>Top India Tour Packages</h6>
                        <p>Discover the beauty and diversity of India with Tanishka Tours, your trusted travel agents in Delhi for offering unforgettable travel experiences. Be it the majestic peaks of the Himalayas or the serene backwaters of Kerala, our packages cover the length and breadth of this incredible country. If you are seeking a cultural immersion in Rajasthan's royal palaces, we have a tour package for you. If you want a spiritual journey to Varanasi, we have the perfect itinerary for you.</p>
                        <p>At Tanishka Tours, we prioritize your comfort and satisfaction. Being one of the top travel agents in Delhi, India, our expert team crafts each tour package with attention to detail, ensuring a seamless and enriching travel experience. Enjoy luxury accommodations, tours, and personalized services that make your trip truly special. Choose Tanishka Tours for your next adventure and let us turn your travel dreams into reality. .</p>
                    </div>

                </Box>
            </Box>

            <div className="allpackages_div">
                <div className="packages_slide">
                    {PackagesData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="packages_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >

            <div className="PopularTours_div">
                <h1 className=" fw-bold py-3">Most Popular Tours In India</h1>
                <div className="tour_slide">
                    {PopularToursIndiaData?.map((item, index) => (
                        <div className="populartour_Slider">
                            <Link to={`/domestic_packages/${item.linkTag}`}>
                                <div key={item.id} className="">
                                    <img src={item.imgSrc} alt={`img-${item.id}`} />
                                    <h4>{item.heading}</h4>
                                    {/* <p>{item.textdetail}</p> */}
                                </div>
                            </Link>
                            <Button onClick={() => handleContactClick('9871927829')} className="booknowbtn" fullWidth>Book Now</Button>
                        </div>
                    ))}
                </div >
                {/* <Button className="butnn">Explore More</Button> */}
            </div >

            <div className="ConsultNow row justify-content-center me-0">
                <div className=" col-lg-6 col-12">
                    <img src={imgeadvisor} alt="img" />
                </div>
                <div className=" col-lg-6 col-12">
                    <h2>Speak to an expert advisor to plan your journey</h2>
                    <Button onClick={() => handleContactClick('9871927829')} className="ConsultNowbtn">Consult Now</Button>
                </div>

            </div>

            <Box className='tanishka_oupackagediv'>
                <div className="tanishka_popular">
                    <h2 className=" fw-bold">Explore Our Exciting International Tour Packages</h2>
                </div>

                <CurveSlider detailsData={InternationalTourPackageData} />
                {/* <Button className="">Explore More</Button> */}

            </Box>

            <div className="PopularTours_div">
                <h1 className=" fw-bold py-3">Balkans Tour packages</h1>
                <div className="tour_slide">
                    {BalkansTourpackageData?.map((item, index) => (
                        <div className="populartour_Slider">
                            <Link to={''}>
                                <div key={item.id} className="">
                                    <img src={item.imgSrc} alt={`img-${item.id}`} />
                                    <h4>{item.heading}</h4>
                                    {/* <p>{item.textdetail}</p> */}
                                </div>
                            </Link>
                            <Button onClick={() => handleContactClick('9871927829')} className="booknowbtn" fullWidth>Book Now</Button>
                        </div>

                    ))}
                </div >
                {/* <Button className="butnn">Explore More</Button> */}
            </div >


            <Box className='bbgspeak'>
                <div className="row me-0">
                    <div className="col-lg-6 col-12">
                        <img className="imgage" src={excitingimg} alt="img" />
                    </div>
                    <div className="col-lg-6 col-12 packagesdivdetail">
                        <h1 className="ourExciting">Explore Our
                            <span>Exciting Tour</span>
                            Packages!
                        </h1>
                        <div>
                            <Button onClick={() => handleContactClick('9871927829')} className="">Customize Tours</Button>
                            <Button onClick={() => handleContactClick('9871927829')} className="">Private Tours</Button>
                            <Button onClick={() => handleContactClick('9871927829')} className="">Tailor Made Tours</Button>
                        </div>
                        <h1>Call Now: +91 9871927829</h1>
                        <Button onClick={() => handleContactClick('9871927829')} className="">Speak to an Expert</Button>
                    </div>
                </div>
            </Box>

            <Box className='athospital_welcomeDiv'>
                <Box className="athospitalhome row">
                    <div className="textdiv col-lg-6 col-12">
                        <p>Get to Know Us</p>
                        <h6>Why Choose Tanishka Tours?</h6>
                        <p className=" fw-semibold text-danger">Best ways to enjoy adventures</p>
                        <p>Choose Tanishka Tours for our expert knowledge and personalized services. Being amongst the best travel agents in India, we offer a seamless and enriching travel experience. Our extensive network and attention to detail guarantee an authentic and memorable exploration across the world.</p>
                        <Button> <Link to={'/about_us'}> About Us</Link></Button>
                    </div>

                    <div className="imggdiv col-lg-6 col-12">
                        <img src={wildlifeimg} alt="img" />
                    </div>

                </Box>
            </Box>

            <div className="TravelOptions">
                <h1 className=" fw-bold py-4">Travel Options Sorted by Categories</h1>
                <div className="TravelOptions_slide">
                    {TravelByCatogryData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="TravelOptions_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >

            <div className="ourprojectgreen">
                <div className="row justify-content-center align-items-center me-0">
                    <div className="col-xs-12 col-sm-6 col-md-3 mb-md-50">
                        <div className="greenbgimg text-center">
                            <img src={happyClient1} alt="img" />
                            <h2 data-animation-duration data-value="10,000,00+" className="animate-number text-white  number-font fw-bold my-2 appeared">50k</h2>
                            <h6 className="text-white text-uppercase font-weight-600">Happy Travellers</h6>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3 mb-md-50">
                        <div className="greenbgimg text-center">
                            <img src={happyClient2} alt="img" />
                            <h2 data-animation-duration data-value="300+" className="animate-number text-white  number-font fw-bold my-2 appeared">+2500</h2>
                            <h6 className="text-white text-uppercase font-weight-600">Premium Tours</h6>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-6 col-md-3 mb-md-50">
                        <div className="greenbgimg text-center">
                            <img src={happyClient3} alt="img" />
                            <h2 data-animation-duration data-value="4000+" className="animate-number text-white  number-font fw-bold my-2 appeared">800+</h2>
                            <h6 className="text-white text-uppercase font-weight-600">Travel Destinations</h6>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3 mb-md-50">
                        <div className="greenbgimg text-center">
                            <img src={happyClient4} alt="img" />
                            <h2 data-animation-duration data-value="20,000+" className="animate-number text-white  number-font fw-bold my-2 appeared">Since 1995</h2>
                            <h6 className="text-white text-uppercase font-weight-600">Trusted Excellence</h6>
                        </div>
                    </div>
                </div>

            </div>

            <div className="TravelOptions">
                <h1 className=" fw-bold py-4">Offering Unique Local Experience</h1>
                <div className="TravelOptions_slide">
                    {LocalTourData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="TravelOptions_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
            </div >

            <Footer />
        </>
    )
}

export default Home