


import LehLadakh1 from '../../assest/taniska/india/LehLadakh/LehLadakh1.jpg'
import LehLadakh2 from '../../assest/taniska/india/LehLadakh/LehLadakh2.jpg'



export const DomesticLehLadakhBannerData = [
    {
        bannerName: 'Leh Ladakh',
        bannerImage: LehLadakh1,
    },
];

const DomesticLehLadakhData = [
    {
        id: 1,
        tripName: 'Leh Ladakh Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Leh Ladakh, India',
        price: "₹20,671",
        imgSrc: LehLadakh2,
        linkTag:'domestic_packages/leh_ladakh/leh_ladakh_detail',
    }

];
export default DomesticLehLadakhData