import comingsoon1 from '../../assest/taniska/balkans5.jpg.webp'
import comingsoon2 from '../../assest/taniska/Bhutan-Tour.jpg'
import comingsoon3 from '../../assest/taniska/Nepal-Tour.jpg'
import comingsoon4 from '../../assest/taniska/Sri-Lanka-tour.jpg'
import comingsoon5 from '../../assest/taniska/Vietnam-Tour.jpg'
import comingsoon6 from '../../assest/taniska/balkans1.jpg.webp'
import comingsoon7 from '../../assest/taniska/balkans2.jpg.webp'
import comingsoon8 from '../../assest/taniska/balkans3.jpg.webp'
import comingsoon9 from '../../assest/taniska/balkans4.jpg.webp'
import comingsoon10 from '../../assest/taniska/abudhabi.webp'
import comingsoon11 from '../../assest/taniska/Andaman-Tour.jpg.webp'
import comingsoon12 from '../../assest/taniska/bali.webp'
import comingsoon13 from '../../assest/taniska/banner1.webp'
import comingsoon14 from '../../assest/taniska/banner2.webp'
import comingsoon15 from '../../assest/taniska/banner3.webp'
import comingsoon16 from '../../assest/taniska/banner4.webp'
import comingsoon17 from '../../assest/taniska/banner5.webp'
import comingsoon18 from '../../assest/taniska/banner6.jpg'
import comingsoon19 from '../../assest/taniska/banner7.webp'
import comingsoon20 from '../../assest/taniska/burjkhalifa.jpg'
import comingsoon21 from '../../assest/taniska/delightful-kashmi.jpeg'
import comingsoon22 from '../../assest/taniska/dubai-marina.webp'
import comingsoon23 from '../../assest/taniska/dubai1.jpg'
import comingsoon24 from '../../assest/taniska/dubai2.jpg'
import comingsoon25 from '../../assest/taniska/dubai3.jpg'
import comingsoon26 from '../../assest/taniska/dubai4.jpg'
import comingsoon27 from '../../assest/taniska/europe.webp'
import comingsoon28 from '../../assest/taniska/usa.webp'
import comingsoon29 from '../../assest/taniska/singalion.jpeg'
import comingsoon30 from '../../assest/taniska/paris.jpeg'
import comingsoon31 from '../../assest/taniska/north-east-tour.jpg.webp'
import comingsoon32 from '../../assest/taniska/manali-shimla-escape.jpeg'
import comingsoon33 from '../../assest/taniska/maldives.jpg'
import comingsoon34 from '../../assest/taniska/malaysiya.webp'
import comingsoon35 from '../../assest/taniska/kerala.jpg.webp'
import comingsoon36 from '../../assest/taniska/kashmir.jpg.webp'
import comingsoon37 from '../../assest/taniska/interantional1.webp'
import comingsoon38 from '../../assest/taniska/interantional2.webp'
import comingsoon39 from '../../assest/taniska/interantional3.webp'
import comingsoon40 from '../../assest/taniska/interantional4.webp'
import comingsoon41 from '../../assest/taniska/interantional5.jpg'
import comingsoon42 from '../../assest/taniska/interantional6.webp'
import comingsoon43 from '../../assest/taniska/interantional7.webp'
import comingsoon44 from '../../assest/taniska/interantional8.webp'
import comingsoon45 from '../../assest/taniska/indophiphi.jpg'




const GallerData = [
    {
        id: 1,
        src: comingsoon1,
    },
    {
        id: 2,
        src: comingsoon2,
    },
    {
        id: 3,
        src: comingsoon3,
    }, {
        id: 4,
        src: comingsoon4,
    }, {
        id: 5,
        src: comingsoon5,
    }, {
        id: 6,
        src:comingsoon6,
    },
     {
        id: 7,
        src:comingsoon7,
    }, {
        id: 8,
        src:comingsoon8,
    }, {
        id: 9,
        src:comingsoon9,
    },
     {
        id: 10,
        src:comingsoon10,
    },
     {
        id: 11,
        src:comingsoon11,
    },
     {
        id: 12,
        src:comingsoon12,
    },
     {
        id: 13,
        src:comingsoon13,
    },
     {
        id: 14,
        src:comingsoon14,
    },
     {
        id: 15,
        src:comingsoon15,
    },
     {
        id: 16,
        src:comingsoon16,
    },
     {
        id: 17,
        src:comingsoon17,
    },
     {
        id: 18,
        src:comingsoon18,
    },
     {
        id: 19,
        src:comingsoon19,
    },
     {
        id: 20,
        src:comingsoon20,
    },
    {
        id: 21,
        src:comingsoon21,
    },
    {
        id: 22,
        src:comingsoon22,
    },
    {
        id: 23,
        src:comingsoon23,
    },
    {
        id: 24,
        src:comingsoon24,
    },
    {
        id: 25,
        src:comingsoon25,
    },
    {
        id: 26,
        src:comingsoon26,
    },
    {
        id: 27,
        src:comingsoon27,
    },
    {
        id: 28,
        src:comingsoon28,
    },
    {
        id: 29,
        src:comingsoon29,
    },
    {
        id: 30,
        src:comingsoon30,
    },
    {
        id: 31,
        src:comingsoon31,
    },
    {
        id: 32,
        src:comingsoon32,
    },
    {
        id: 33,
        src:comingsoon33,
    },
    {
        id: 34,
        src:comingsoon34,
    },
    {
        id: 35,
        src:comingsoon35,
    },
    {
        id: 36,
        src:comingsoon36,
    },
    {
        id: 37,
        src:comingsoon37,
    },
    {
        id: 38,
        src:comingsoon38,
    },
    {
        id: 39,
        src:comingsoon39,
    },
    {
        id: 40,
        src:comingsoon40,
    },
    {
        id: 41,
        src:comingsoon41,
    },
    {
        id: 42,
        src:comingsoon42,
    },
    {
        id: 43,
        src:comingsoon43,
    },
    {
        id: 44,
        src:comingsoon44,
    },
    {
        id: 45,
        src:comingsoon45,
    },
    
];
export default GallerData;