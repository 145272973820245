
import LocationDetailPage from "../../components/LocationDetailPage"
import InternationalNepalData, { InternationalNepalBannerData } from "../../data/tanishkaData/InternationalNepalData"

const InternationalNepal = () => {
  return (
    <>
      <LocationDetailPage BookingData={InternationalNepalData} bookingBannerData={InternationalNepalBannerData} />
    </>
  )
}

export default InternationalNepal