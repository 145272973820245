import Bhutan1 from '../../assest/taniska/Bhutan/Bhutan1.png'
import Bhutan2 from '../../assest/taniska/Bhutan/Bhutan2.png'
import Bhutan3 from '../../assest/taniska/Bhutan/Bhutan3.png'
import Bhutan4 from '../../assest/taniska/Bhutan-Tour.jpg'



const InternationalBhutanDetailBannerData = [
    {
        id: 1,
        name: 'Bhutan Tour',
        src: Bhutan1,
    },
    {
        id: 2,
        name: 'Bhutan Tour',
        src: Bhutan2,
    },
    {
        id: 3,
        name: 'Bhutan Tour',
        src: Bhutan3,
    },
    {
        id: 4,
        name: 'Bhutan Tour',
        src: Bhutan4,
    },
    
];
export default InternationalBhutanDetailBannerData


export const InternationalBhutanTextData = [
    {
      detailText: "Bhutan is a land lock country in South Asia that is located on the southern slopes of the Eastern Himalayas. Bhutan is the second least populous country after the Maldives in the South Asia region. The capital city of this country is Thimpu and it is also the largest city of Bhutan. Bhutan has marked its presence because of the national identity of Buddhism. Bhutan is also famous for its wildlife diversity and Gangkhar Puensum is the highest peak of this country that is never climbed by anyone till its peak. Bhutan is rich in flora and fauna as this country is full of dense forests, glaciated mountain peaks, beautiful waterfalls emerging from the mountains, alpine valleys, dense vegetation, porous soil and mountain rivers. There are some rare species of animals that are found in Bhutan such as the golden langurs. It is also home to many other animals that are being protected in wildlife sanctuaries and national parks and zoos. Bhutan is the top first country in South Asia in terms of economic freedom as it is the least corrupted nation that provides utmost ease in carrying out business activities. Bhutan’s home currency is Ngultrum. Although Bhutan is a very small country, it has made a significant economic development in the past few years. It is mostly based on agriculture industry that covers around 55% but there are other sectors too that contribute greatly to its economy such as the handicrafts, metals, tourism, cottage industry and forestry. Buddhism is the predominant religion in Bhutan followed by Hinduism and then other religions. Bhutan has recently become a very high-value tourist destination as it charges heavily from its tourists for accommodation and tours. Bhutan is a centre of wildlife sanctuaries, pilgrimage sites, cultural heritage, religious festivals, national parks, ancient sculptures and scenic mountains. All these things attract a lot of tourists to visit the beautiful country of Bhutan."
    }
];