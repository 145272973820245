
import LocationDetailPage from "../../components/LocationDetailPage"
import DomesticPunjabData, { DomesticPunjabBannerData } from "../../data/tanishkaData/DomesticPunjabData"

const DomesticPunjab = () => {
  return (
    <>
      <LocationDetailPage BookingData={DomesticPunjabData} bookingBannerData={DomesticPunjabBannerData} />
    </>
  )
}

export default DomesticPunjab