
import ViewDetail from "../../components/ViewDetail"
import DomesticRajasthanDetailBannerData, { DomesticRajasthanTextData } from "../../data/tanishkaData/DomesticRajasthanDetailData"

const DomesticRajasthanDetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={DomesticRajasthanDetailBannerData} aboutTourInfo={DomesticRajasthanTextData} />
    </>
  )
}

export default DomesticRajasthanDetail