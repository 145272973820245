
import LocationDetailPage from "../../components/LocationDetailPage"
import InternationalUSAData, { InternationalUSABannerData } from "../../data/tanishkaData/InternationalUSAData"

const InternationalUSA = () => {
  return (
    <>
      <LocationDetailPage BookingData={InternationalUSAData} bookingBannerData={InternationalUSABannerData} />
    </>
  )
}

export default InternationalUSA