import people1 from '../../assest/taniska/interantional1.webp'
import people2 from '../../assest/taniska/indophiphi.jpg'
import people3 from '../../assest/taniska/interantional3.webp'
import people4 from '../../assest/taniska/interantional4.webp'
import people5 from '../../assest/taniska/cape-town.jpg'
import people6 from '../../assest/taniska/interantional6.webp'
import people7 from '../../assest/taniska/interantional7.webp'
import people8 from '../../assest/taniska/interantional8.webp'
import people9 from '../../assest/taniska/Vietnam-Tour.jpg'
import people10 from '../../assest/taniska/maldives.jpg'
import people11 from '../../assest/taniska/europe.webp'
import people12 from '../../assest/taniska/usa.webp'
import people13 from '../../assest/taniska/Bhutan-Tour.jpg'
import people14 from '../../assest/taniska/Nepal-Tour.jpg'




const InternationalPackagesDetailPageBannerData = [
    {
        id: 1,
        name: 'International Tour Packages',
        src: people1,
    },
    {
        id: 2,
        name: 'International Tour Packages',
        src: people2,
    },
    {
        id: 3,
        name: 'International Tour Packages',
        src: people3,
    },
    {
        id: 4,
        name: 'International Tour Packages',
        src: people4,
    },
    {
        id: 5,
        name: 'International Tour Packages',
        src: people5,
    },
    {
        id: 6,
        name: 'International Tour Packages',
        src: people6,
    },
    {
        id: 7,
        name: 'International Tour Packages',
        src: people7,
    },
    {
        id: 8,
        name: 'International Tour Packages',
        src: people8,
    }
    ,
    {
        id: 9,
        name: 'International Tour Packages',
        src: people9,
    }
    ,
    {
        id: 10,
        name: 'International Tour Packages',
        src: people10,
    }
    ,
    {
        id: 11,
        name: 'International Tour Packages',
        src: people11,
    },
    {
        id: 12,
        name: 'International Tour Packages',
        src: people12,
    },
    {
        id: 13,
        name: 'International Tour Packages',
        src: people13,
    },
    {
        id: 14,
        name: 'International Tour Packages',
        src: people14,
    },
    

];
export default InternationalPackagesDetailPageBannerData


export const InternationalPackagesDetailPageTextData = [
    {
        detailText: "A flawless vacation by having an international tour to all the major tourist destinations is a dream of everyone. But only few people who are determined to make this dream into reality and believe in themselves can fulfill it and us, Tanishka Travels help in making dreams come true. There are thousands of cities that are world-wide famous among travelers and wanderers. There are hundreds of countries that are mind-blowing and worth the visit. Dubai, Singapore, United States of America, Maldives, Bangkok, Kathmandu, Australia, Mauritius, Sri Lanka, Thailand, Malaysia, Paris, Germany, London, Italy, Bali, Las Vegas, New York, Switzerland, Amsterdam, Rome, Hong Kong, Qatar, Los Angeles, Spain, Canada, Budapest, Moscow, Florida and Hawaii are some of the international tour destinations that provide a breathtaking experience with its top attractions. All the nations and cities are unique in their own way and are definitely worth the visit. Music in England, salsa in Spain, Eiffel Tower in Paris, beer in Germany, chocolates in Switzerland, coffee culture and ice-cream in Italy, vodka in Russia, traditions in Greece, politeness in Japan, tourism welcome in Thailand, Mecca Madina in Saudi Arabia, fashion in Paris, spices in Istanbul, glitzy lifestyle in Dubai, wine in France, deserts in Afghanistan, beaches in Australia and freedom in America are all the highlights that make these places a most-visit one. Contact Tanishka Travels and get in touch with international tour operators in order to know more about the international holiday packages."
    }

];