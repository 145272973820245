
import Europe1 from '../../assest/taniska/europe/ParisFrance.jpg'
import Europe2 from '../../assest/taniska/europe/Budapest Hungary.jpg'
import Europe3 from '../../assest/taniska/europe/Monte Isola Italy.jpg'
import Europe4 from '../../assest/taniska/europe/Munich.jpeg'
import Europe5 from '../../assest/taniska/europe/Switzerland.png'
import Europe6 from '../../assest/taniska/europe.webp'




const InternationalEuropeDetailBannerData = [
    {
        id: 1,
        name: 'Europe Tour',
        src: Europe5,
    },
    {
        id: 2,
        name: 'Europe Tour',
        src: Europe2,
    },
    {
        id: 3,
        name: 'Europe Tour',
        src: Europe3,
    },
    {
        id: 4,
        name: 'Europe Tour',
        src: Europe4,
    },
    {
        id: 5,
        name: 'Europe Tour',
        src: Europe1,
    },
    {
        id: 6,
        name: 'Europe Tour',
        src: Europe6,
    },
];
export default InternationalEuropeDetailBannerData


export const InternationalEuropeTextData = [
    {
        detailText: "Europe, a continent of several beautiful countries. It is one of the largest continents in the world. It consists of 50 sovereign states. Surrounded by large bodies of water, Europe is also called ‘Continental Europe’. Europe was very rich in flora and fauna. But today, due to man’s activities, Europe is heavily endangered zone for all the species of plants and animals that inhabit this continent. Europe is currently considered the largest and the wealthiest continent in the world. It has the largest single economic region comprising of 28 European states. According to the World Population Prospects, in 2017, Europe covered one-ninth of the total world’s population. Europe is a continent that consists of around 225 indigenous languages. Latin, Roman, Germanic and Slavic are some of the major groups of languages that are found in this continent. Christianity religion is predominant in Europe influenced by European art and culture. The second most predominant religion found here is Islam. Other religions such as Hinduism, Buddhism and Judaism also prevail in this continent. The largest urban cities of this continent includes Istanbul, Paris, Moscow, Berlin, Rome, London, Paris, Madrid, Barcelona and many more. Istanbul and London are called as the megacities as they are the most populous cities in this continent. Talking about major countries in this continent, they are Belgium, Denmark, Germany, France, Greece, Iceland, Italy, Norway, Russia, Serbia, Spain, Switzerland, United Kingdom, Turkey and many more. All these countries and their magnificent cities are the attractions that make Europe tour places world-famous. Europe tour packages help you in discovering the beauty of these countries."
    }

];