
import ViewDetail from "../../components/ViewDetail"
import InternationalBhutanDetailBannerData, { InternationalBhutanTextData } from "../../data/tanishkaData/InternationalBhutanDetailData"

const InternationalBhutanDetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={InternationalBhutanDetailBannerData} aboutTourInfo={InternationalBhutanTextData} />
    </>
  )
}

export default InternationalBhutanDetail