

import Himachal1 from '../../assest/taniska/india/Himachal/himachal_pradesh5.jpg'
import Himachal2 from '../../assest/taniska/india/Himachal/himachal_pradesh2.png'





export const DomesticHimachalPradeshBannerData = [
    {
        bannerName: 'Himachal Pradesh',
        bannerImage: Himachal2,
    },
];

const DomesticHimachalPradeshData = [
    {
        id: 1,
        tripName: 'Himachal Pradesh Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Himachal Pradesh, India',
        price: "₹20,671",
        imgSrc: Himachal1,
        linkTag:'domestic_packages/himachal_pradesh/himachal_pradesh_detail',
    }

];
export default DomesticHimachalPradeshData