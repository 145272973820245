
import ViewDetail from "../../components/ViewDetail"
import InternationalSriLankaDetailBannerData, { InternationalSriLankaTextData } from "../../data/tanishkaData/InternationalSriLankaDetailData"

const InternationalSriLankaDetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={InternationalSriLankaDetailBannerData} aboutTourInfo={InternationalSriLankaTextData} />
    </>
  )
}

export default InternationalSriLankaDetail