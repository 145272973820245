import { Box, Button, Rating } from "@mui/material"
import Footer from "../common/TanishkaEnterprises/Footer/Footer"
import Header from "../common/TanishkaEnterprises/Header/Header"
import '../styles/ViewDetailStyle.css'
import { FaHome } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import TopSliderAllMax from "../common/TanishkaEnterprises/TopSliderAllMarx/TopSliderAllMarx";
import DubaiData from "../data/tanishkaData/DubaiData";
import hotelimg from '../assest/taniska/hotel.png'
import logimg from '../assest/taniska/image.png'
import busimg from '../assest/taniska/bus.png'
import eatimg from '../assest/taniska/eat.png'
import docsimg from '../assest/taniska/google-docs.png'
import notesimg from '../assest/taniska/notes.png'
import { Link } from "react-router-dom";
import PopularToursIndiaData from "../data/tanishkaData/PopularToursIndiaData";
import DubaiPackageData from "../data/tanishkaData/DubaiPackageData";
import { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import styled from "styled-components";
import GallerData from "../data/tanishkaData/GallerData";
import DetailGalleryImages from "../common/TanishkaEnterprises/DetailGallery/DetailGalleryImages";
import { MdWhatsapp } from "react-icons/md";





const MyTitleMessage = styled.h1`
  position: absolute;
  width: 99.5%;
  top: 20rem;
  z-index: 1;
  margin-top: -70px;
  text-align: center;
   text-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
  strong {
    font-size: 1.25em;
    font-weight: bold;
    letter-spacing: 2px;

  }
  div {
    color: white;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4),0px 2px 5px rgba(0, 0, 0, 0.4);
    font-weight: 100;
    .main {
      font-size: 50px;
    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    top: 18rem;
    div {
      .main {
        font-size: 40px;
      }
    }
  }

  @media (max-width: 767px) {
    top: 10rem;
    width: 98%;
    div {
      .main {
        font-size: 18px;
      }
    }
  }
`;



const ViewDetail = ({ TourBannersData, aboutTourInfo }) => {

    const [expandedItems, setExpandedItems] = useState({});

    const toggleExpand = (id) => {
        setExpandedItems(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const renderTextDetail = (text, isExpanded) => {
        const shortText = text.length > 750 ? text.substring(0, 750) + '...' : text;
        return isExpanded ? text : shortText;
    };

    const handleContactClick = (phoneNumber) => {
        window.location.href = `whatsapp://send?phone=${encodeURIComponent(phoneNumber)}`;
    };

    return (
        <>
            <Header />
            {/* <div className="row align-items-center mainview">
                <div className="locationview col-lg-3">
                    <h1><FaHome className=" me-1" /> Group Tour - Best of Dubai</h1>
                    <p>6 D | 5 N,<IoLocationSharp /> Dubai</p>
                </div>
                <div className=" col-lg-3 Departurebox">
                    <p>Departure</p>
                    <select className=" form-control">
                        <option>Mumbai</option>
                    </select>
                </div>
                <div className=" col-lg-3 STARTINGFROMdiv">
                    <p>STARTING FROM</p>
                    <h1>INR 99,773</h1>
                    <p>PER PERSON</p>
                </div>
                <div className=" col-lg-3 buttonsss">
                    <Button className="btnn1">Send Enquiry</Button>
                    <Button className="btnn2">Books Now</Button>
                </div>

            </div> */}

            <Box id="BoxId">
                <Swiper
                    centeredSlides={true}
                    spaceBetween={0}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Pagination]}
                    className=""
                >
                    {TourBannersData?.map(({ id, name, src }) => {
                        return (
                            <SwiperSlide key={id} className="">
                                <div className="custom-img">
                                    <img src={src} alt="img" />
                                </div>

                                <MyTitleMessage>
                                    <div className="titleMessage">
                                        <div className="heading">
                                            <div className="main text-center mb-3">
                                                <span>
                                                    <strong style={{ textTransform: 'uppercase' }}>{name}</strong>
                                                </span>
                                            </div>

                                        </div>
                                    </div>
                                </MyTitleMessage>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Box>



            <div className="PackageIncludes">
                <h6>Package Includes</h6>
                <div className="boxbottom">
                    <ul>
                        <li>
                            <img src={hotelimg} alt="img" />
                            <p>Hotels</p>
                        </li>
                        <li>
                            <img src={logimg} alt="img" />
                            <p>Sightseeing</p>
                        </li>

                        <li>
                            <img src={eatimg} alt="img" />
                            <p>Meals</p>
                        </li>
                        <li>
                            <img src={docsimg} alt="img" />
                            <p>Itinerary</p>
                        </li>
                        <li>
                            <img src={notesimg} alt="img" />
                            <p>Terms & Conditions</p>
                        </li>

                    </ul>

                </div>
                {aboutTourInfo?.map((item, index) => (
                    <div className="about_info_text">
                        <p>{renderTextDetail(item.detailText, expandedItems[item.id])}</p>
                        <div>
                            <button className="showHidebtn1" onClick={() => toggleExpand(item.id)}>
                                {expandedItems[item.id] ? 'Show Less' : 'Show More'}
                            </button>
                            <button className="showHidebtn2" onClick={() => handleContactClick('9871927829')}>
                                <MdWhatsapp className="me-2 fs-4" />  Book Now
                            </button>
                        </div>
                    </div>

                ))}

                <p className="PriceIncludes"><strong>Price Includes:</strong> 1 Hotel(s), 4 Sightseeing, Flight, All Meals, Guided Tours, Visa, Travel Insurance, All Transfers</p>
            </div>


            <DetailGalleryImages ImageGalleryData={TourBannersData} title={"You might also like..."} />

            {/* 
            <div className="PackageTours_div">
                <h3 className=" fw-bold py-3">Day 01</h3>
                <div className="Packagetour_slide">
                    {DubaiPackageData?.map((item, index) => (
                        <>
                            <div key={item.id} className="detailtour_packages">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                                <div className="">
                                    <p>{renderTextDetail(item.textdetail, expandedItems[item.id])}</p>
                                    <button className="showbtnnn" onClick={() => toggleExpand(item.id)}>
                                        {expandedItems[item.id] ? 'Show Less' : 'Show More'}
                                    </button>
                                </div>
                                <p>Sight Seeing Included: <b>{item.SightSeeing}</b></p>
                                <div className="LunchDinner">
                                    <div className="divdinner_lunch">
                                        <div>
                                            <img src={eatimg} alt="img" />
                                        </div>
                                        <div>
                                            <p>Lunch</p>
                                            <p className=" text-success">Included</p>
                                        </div>
                                    </div>

                                    <div className="divdinner_lunch">
                                        <div>
                                            <img src={eatimg} alt="img" />
                                        </div>
                                        <div>
                                            <p>Dinner</p>
                                            <p className=" text-success">Included</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div key={item.id} className="detailtour_packages">
                                <img src={item.hotelimg} alt={`img-${item.id}`} />
                                <h4>Omega Hotel</h4>
                                <Rating size="small" className="my-2 mx-3" name="read-only" value={4} readOnly />
                                <p>98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends Colony, New Delhi-110025</p>
                                <p><b>Room Included :Standard</b></p>
                                <p><b>Meals at hotel : CP included only</b></p>
                            </div>
                        </>

                    ))}
                </div >
            </div >

            <div className="PackageTours_div">
                <h3 className=" fw-bold py-3">Day 02</h3>
                <div className="Packagetour_slide">
                    {DubaiPackageData?.map((item, index) => (
                        <>
                            <div key={item.id} className="detailtour_packages">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                                <div className="">
                                    <p>{renderTextDetail(item.textdetail, expandedItems[item.id])}</p>
                                    <button className="showbtnnn" onClick={() => toggleExpand(item.id)}>
                                        {expandedItems[item.id] ? 'Show Less' : 'Show More'}
                                    </button>
                                </div>
                                <p>Sight Seeing Included: <b>{item.SightSeeing}</b></p>

                                <div className="LunchDinner">
                                    <div className="divdinner_lunch">
                                        <div>
                                            <img src={eatimg} alt="img" />
                                        </div>
                                        <div>
                                            <p>Breakfast</p>
                                            <p className=" text-success">Included</p>
                                        </div>
                                    </div>
                                    <div className="divdinner_lunch">
                                        <div>
                                            <img src={eatimg} alt="img" />
                                        </div>
                                        <div>
                                            <p>Lunch</p>
                                            <p className=" text-success">Included</p>
                                        </div>
                                    </div>

                                    <div className="divdinner_lunch">
                                        <div>
                                            <img src={eatimg} alt="img" />
                                        </div>
                                        <div>
                                            <p>Dinner</p>
                                            <p className=" text-success">Included</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div key={item.id} className="detailtour_packages">
                                <img src={item.hotelimg} alt={`img-${item.id}`} />
                                <h4>Omega Hotel</h4>
                                <Rating size="small" className="my-2 mx-3" name="read-only" value={4} readOnly />
                                <p>98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends Colony, New Delhi-110025</p>
                                <p><b>Room Included :Standard</b></p>
                                <p><b>Meals at hotel : CP included only</b></p>
                            </div>
                        </>

                    ))}
                </div >
            </div >

            <div className="PackageTours_div">
                <h3 className=" fw-bold py-3">Day 03</h3>
                <div className="Packagetour_slide">
                    {DubaiPackageData?.map((item, index) => (
                        <>
                            <div key={item.id} className="detailtour_packages">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                                <div className="">
                                    <p>{renderTextDetail(item.textdetail, expandedItems[item.id])}</p>
                                    <button className="showbtnnn" onClick={() => toggleExpand(item.id)}>
                                        {expandedItems[item.id] ? 'Show Less' : 'Show More'}
                                    </button>
                                </div>
                                <p>Sight Seeing Included: <b>{item.SightSeeing}</b></p>

                                <div className="LunchDinner">
                                    <div className="divdinner_lunch">
                                        <div>
                                            <img src={eatimg} alt="img" />
                                        </div>
                                        <div>
                                            <p>Breakfast</p>
                                            <p className=" text-success">Included</p>
                                        </div>
                                    </div>
                                    <div className="divdinner_lunch">
                                        <div>
                                            <img src={eatimg} alt="img" />
                                        </div>
                                        <div>
                                            <p>Lunch</p>
                                            <p className=" text-success">Included</p>
                                        </div>
                                    </div>

                                    <div className="divdinner_lunch">
                                        <div>
                                            <img src={eatimg} alt="img" />
                                        </div>
                                        <div>
                                            <p>Dinner</p>
                                            <p className=" text-success">Included</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div key={item.id} className="detailtour_packages">
                                <img src={item.hotelimg} alt={`img-${item.id}`} />
                                <h4>Omega Hotel</h4>
                                <Rating size="small" className="my-2 mx-3" name="read-only" value={4} readOnly />
                                <p>98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends Colony, New Delhi-110025</p>
                                <p><b>Room Included :Standard</b></p>
                                <p><b>Meals at hotel : CP included only</b></p>
                            </div>
                        </>

                    ))}
                </div >
            </div >

            <div className="PackageTours_div">
                <h3 className=" fw-bold py-3">Day 04</h3>
                <div className="Packagetour_slide">
                    {DubaiPackageData?.map((item, index) => (
                        <>
                            <div key={item.id} className="detailtour_packages">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                                <div className="">
                                    <p>{renderTextDetail(item.textdetail, expandedItems[item.id])}</p>
                                    <button className="showbtnnn" onClick={() => toggleExpand(item.id)}>
                                        {expandedItems[item.id] ? 'Show Less' : 'Show More'}
                                    </button>
                                </div>
                                <p>Sight Seeing Included: <b>{item.SightSeeing}</b></p>

                                <div className="LunchDinner">
                                    <div className="divdinner_lunch">
                                        <div>
                                            <img src={eatimg} alt="img" />
                                        </div>
                                        <div>
                                            <p>Breakfast</p>
                                            <p className=" text-success">Included</p>
                                        </div>
                                    </div>
                                    <div className="divdinner_lunch">
                                        <div>
                                            <img src={eatimg} alt="img" />
                                        </div>
                                        <div>
                                            <p>Lunch</p>
                                            <p className=" text-success">Included</p>
                                        </div>
                                    </div>

                                    <div className="divdinner_lunch">
                                        <div>
                                            <img src={eatimg} alt="img" />
                                        </div>
                                        <div>
                                            <p>Dinner</p>
                                            <p className=" text-success">Included</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div key={item.id} className="detailtour_packages">
                                <img src={item.hotelimg} alt={`img-${item.id}`} />
                                <h4>Omega Hotel</h4>
                                <Rating size="small" className="my-2 mx-3" name="read-only" value={4} readOnly />
                                <p>98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends Colony, New Delhi-110025</p>
                                <p><b>Room Included :Standard</b></p>
                                <p><b>Meals at hotel : CP included only</b></p>
                            </div>
                        </>

                    ))}
                </div >
            </div >

            <div className="PackageTours_div">
                <h3 className=" fw-bold py-3">Day 05</h3>
                <div className="Packagetour_slide">
                    {DubaiPackageData?.map((item, index) => (
                        <>
                            <div key={item.id} className="detailtour_packages">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                                <div className="">
                                    <p>{renderTextDetail(item.textdetail, expandedItems[item.id])}</p>
                                    <button className="showbtnnn" onClick={() => toggleExpand(item.id)}>
                                        {expandedItems[item.id] ? 'Show Less' : 'Show More'}
                                    </button>
                                </div>
                                <p>Sight Seeing Included: <b>{item.SightSeeing}</b></p>

                                <div className="LunchDinner">
                                    <div className="divdinner_lunch">
                                        <div>
                                            <img src={eatimg} alt="img" />
                                        </div>
                                        <div>
                                            <p>Breakfast</p>
                                            <p className=" text-success">Included</p>
                                        </div>
                                    </div>
                                    <div className="divdinner_lunch">
                                        <div>
                                            <img src={eatimg} alt="img" />
                                        </div>
                                        <div>
                                            <p>Lunch</p>
                                            <p className=" text-success">Included</p>
                                        </div>
                                    </div>

                                    <div className="divdinner_lunch">
                                        <div>
                                            <img src={eatimg} alt="img" />
                                        </div>
                                        <div>
                                            <p>Dinner</p>
                                            <p className=" text-success">Included</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div key={item.id} className="detailtour_packages">
                                <img src={item.hotelimg} alt={`img-${item.id}`} />
                                <h4>Omega Hotel</h4>
                                <Rating size="small" className="my-2 mx-3" name="read-only" value={4} readOnly />
                                <p>98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends Colony, New Delhi-110025</p>
                                <p><b>Room Included :Standard</b></p>
                                <p><b>Meals at hotel : CP included only</b></p>
                            </div>
                        </>

                    ))}
                </div >
            </div >

            <div className="PackageTours_div">
                <h3 className=" fw-bold py-3">Day 06</h3>
                <div className="Packagetour_slide">
                    {DubaiPackageData?.map((item, index) => (
                        <>
                            <div key={item.id} className="detailtour_packages">
                                <img src={item.hotelimg} alt={`img-${item.id}`} />
                                <h4>Day 6: Dubai - Goodbye!</h4>
                                <p>And here comes an end to the joyous vacation as the last day of your Dubai holiday package arrives!</p>
                                <p>Have a scrumptious breakfast at the hotel and spend the early part of the day at leisure. Shop for some sweet Emirati souvenirs before getting transferred to the airport for boarding your flight back home with beautiful memories of your Dubai trip.</p>
                                <div className="LunchDinner">
                                    <div className="divdinner_lunch">
                                        <div>
                                            <img src={eatimg} alt="img" />
                                        </div>
                                        <div>
                                            <p>Breakfast</p>
                                            <p className=" text-success">Included</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>

                    ))}
                </div >
            </div > */}

            <Footer />


        </>
    )
}

export default ViewDetail