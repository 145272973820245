
import LocationDetailPage from "../../components/LocationDetailPage"
import DomesticGoaData, { DomesticGoaBannerData } from "../../data/tanishkaData/DomesticGoaData"

const DomesticGoa = () => {
  return (
    <>
      <LocationDetailPage BookingData={DomesticGoaData} bookingBannerData={DomesticGoaBannerData} />
    </>
  )
}

export default DomesticGoa