import ViewDetail from "../../components/ViewDetail"
import InternationalUSADetailBannerData, { InternationalUSATextData } from "../../data/tanishkaData/InternationalUSADetailData"

const InternationalUSADetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={InternationalUSADetailBannerData} aboutTourInfo={InternationalUSATextData} />
    </>
  )
}

export default InternationalUSADetail