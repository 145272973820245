import Nepal2 from '../../assest/taniska/Nepal-Tour.jpg'
import Nepal1 from '../../assest/taniska/Nepal/Nepal1.png'




export const InternationalNepalBannerData = [
    {
        bannerName: 'Nepal',
        bannerImage: Nepal1,
    },
];

const InternationalNepalData = [
    {
        id: 1,
        tripName: 'Nepal Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Nepal',
        price: "₹20,671",
        imgSrc: Nepal2,
        linkTag:'international_packages/nepal/nepal_detail',
    },
   

];
export default InternationalNepalData