
import ViewDetail from "../../components/ViewDetail"
import InternationalEuropeDetailBannerData, { InternationalEuropeTextData } from "../../data/tanishkaData/InternationalEuropeDetailData"

const InternationalEuropeDetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={InternationalEuropeDetailBannerData} aboutTourInfo={InternationalEuropeTextData} />
    </>
  )
}

export default InternationalEuropeDetail