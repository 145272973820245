import people1 from '../../assest/taniska/domestic1.webp'
import people2 from '../../assest/taniska/domestic2.webp'
import people3 from '../../assest/taniska/domestic3.webp'
import people4 from '../../assest/taniska/domestic4.webp'
import people5 from '../../assest/taniska/domestic5.webp'
import people6 from '../../assest/taniska/domestic6.webp'
import people7 from '../../assest/taniska/domestic7.webp'
import people8 from '../../assest/taniska/domestic8.webp'
import people9 from '../../assest/taniska/domestic9.webp'
import people10 from '../../assest/taniska/domestic10.webp'
import people11 from '../../assest/taniska/domestic11.webp'
import people12 from '../../assest/taniska/domestic12.webp'
import people13 from '../../assest/taniska/domestic13.webp'
import people14 from '../../assest/taniska/domestic14.webp'
import people15 from '../../assest/taniska/domestic15.webp'
import people16 from '../../assest/taniska/domestic16.webp'
import people17 from '../../assest/taniska/domestic17.webp'
import people18 from '../../assest/taniska/domestic18.webp'
import people19 from '../../assest/taniska/domestic19.webp'




const DomesticPackagesDetailPageBannerData = [
    {
        id: 1,
        name: 'Domestic Tour Packages',
        src: people1,
    },
    {
        id: 2,
        name: 'Domestic Tour Packages',
        src: people2,
    },
    {
        id: 3,
        name: 'Domestic Tour Packages',
        src: people3,
    },
    {
        id: 4,
        name: 'Domestic Tour Packages',
        src: people4,
    },
    {
        id: 5,
        name: 'Domestic Tour Packages',
        src: people5,
    },
    {
        id: 6,
        name: 'Domestic Tour Packages',
        src: people6,
    },
    {
        id: 7,
        name: 'Domestic Tour Packages',
        src: people7,
    },
    {
        id: 8,
        name: 'Domestic Tour Packages',
        src: people8,
    }
    ,
    {
        id: 9,
        name: 'Domestic Tour Packages',
        src: people9,
    }
    ,
    {
        id: 10,
        name: 'Domestic Tour Packages',
        src: people10,
    }
    ,
    {
        id: 11,
        name: 'Domestic Tour Packages',
        src: people11,
    },
    {
        id: 12,
        name: 'Domestic Tour Packages',
        src: people12,
    },
    {
        id: 13,
        name: 'Domestic Tour Packages',
        src: people13,
    },
    {
        id: 14,
        name: 'Domestic Tour Packages',
        src: people14,
    },
    {
        id: 15,
        name: 'Domestic Tour Packages',
        src: people15,
    },
    {
        id: 16,
        name: 'Domestic Tour Packages',
        src: people16,
    },
    {
        id: 16,
        name: 'Domestic Tour Packages',
        src: people16,
    },
    {
        id: 17,
        name: 'Domestic Tour Packages',
        src: people17,
    },
    {
        id: 18,
        name: 'Domestic Tour Packages',
        src: people18,
    }
    ,
    {
        id: 19,
        name: 'Domestic Tour Packages',
        src: people19,
    }

];
export default DomesticPackagesDetailPageBannerData


export const DomesticPackagesDetailPageTextData = [
    {
        detailText: "India is a country located in South Asia known for its vibrant culture, rich history, stunning natural beauty, and diverse landscapes. India boasts a diverse range of landscapes, including snow-capped mountains, pristine beaches, dense forests, and arid deserts. Some of the most beautiful places in India include the Himalayas, the backwaters of Kerala, the beaches of Goa, and the Thar Desert in Rajasthan. India is home to some of the most beautiful and intricately designed structures in the world, including the Taj Mahal, one of the seven wonders of the world. Other notable structures include the forts and palaces of Rajasthan, the ancient temples of Khajuraho, and the rock-cut caves of Ajanta and Ellora. India is home to a diverse range of wildlife, including tigers, elephants, leopards, and various species of birds. Some of the most famous national parks in India include Bandhavgarh National Park, Kanha National Park, and Ranthambore National Park. India is known for its vibrant and diverse culture, which is reflected in its art, music, dance, and festivals. India is famous for its diverse and delicious cuisine, which varies from region to region. India is a beautiful country with a lot to offer to visitors. Explore our specially curated tour itineraries for top tourist destinations in India."
    }

];