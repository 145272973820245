

import LocationDetailPage from "../../components/LocationDetailPage"
import DomesticLehLadakhData, { DomesticLehLadakhBannerData } from "../../data/tanishkaData/DomesticLehLadakhData"

const DomesticLehLadakh = () => {
  return (
    <>
      <LocationDetailPage BookingData={DomesticLehLadakhData} bookingBannerData={DomesticLehLadakhBannerData} />
    </>
  )
}

export default DomesticLehLadakh