import ViewDetail from "../../components/ViewDetail"
import DomesticGoaDetailBannerData, { DomesticGoaTextData } from "../../data/tanishkaData/DomesticGoaDetailData"

const DomesticGoaDetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={DomesticGoaDetailBannerData} aboutTourInfo={DomesticGoaTextData} />
    </>
  )
}

export default DomesticGoaDetail