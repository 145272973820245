
import ViewDetail from "../../components/ViewDetail"
import InternationalThailandDetailBannerData, { InternationalThailandTextData } from "../../data/tanishkaData/InternationalThailandDetailData"

const InternationalThailandDetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={InternationalThailandDetailBannerData} aboutTourInfo={InternationalThailandTextData} />
    </>
  )
}

export default InternationalThailandDetail