
import people1 from '../../assest/taniska/pack1.jpg'
import people2 from '../../assest/taniska/pack2.jpg'
import people3 from '../../assest/taniska/pack3.jpg'
import people4 from '../../assest/taniska/pack4.jpg'
import people5 from '../../assest/taniska/pack5.jpg'
import people6 from '../../assest/taniska/pack6.jpg'



const PackagesData = [
    {
        id: 1,
        heading:'Taj Mahal Tour Packages',
        imgSrc: people1,
    },
    {
        id: 2,
        heading:'Amritsar Tour Packages',
        imgSrc: people2,
    },
    {
        id: 3,
        heading:'Golden Triangle Tour Packages',
        imgSrc: people3,
    } ,
     {
        id: 4,
        heading:'Leh Ladakh Tour Packages',
        imgSrc: people4,
    },
     {
        id: 5,
        heading:'North East Tour Packages ',
        imgSrc: people5,
    }, {
        id: 6,
        heading:'South India Tour Packages ',
        imgSrc: people6,
    }
   
];
export default PackagesData