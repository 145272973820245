import ViewDetail from "../../components/ViewDetail"
import InternationalMalaysiaDetailBannerData, { InternationalMalaysiaTextData } from "../../data/tanishkaData/InternationalMalaysiaDetailData"

const InternationalMalaysiaDetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={InternationalMalaysiaDetailBannerData} aboutTourInfo={InternationalMalaysiaTextData} />
    </>
  )
}

export default InternationalMalaysiaDetail