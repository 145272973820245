import Header from "../common/TanishkaEnterprises/Header/Header"
import Footer from "../common/TanishkaEnterprises/Footer/Footer"
import polygon from '../assest/taniska/fixed.jpg'
import { Box, Button, Checkbox } from "@mui/material"
import { IoMdArrowDropdown } from "react-icons/io";
import TopLocationData from "../data/tanishkaData/TopLocationData";
import BalkansTourpackageData from "../data/tanishkaData/BalkansTourpackageData";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import '../styles/fixedDepartureStyle.css'

import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'
import CategoryData from "../data/tanishkaData/CategoryData";
// import { MultiSelect } from 'primereact/multiselect';
// import "primereact/resources/themes/lara-light-cyan/theme.css";
import { GiAnticlockwiseRotation } from "react-icons/gi";
import hotelimg from '../assest/taniska/hotel.png'
import logimg from '../assest/taniska/image.png'
import busimg from '../assest/taniska/bus.png'
import eatimg from '../assest/taniska/eat.png'
import emailjs from "@emailjs/browser";
import { useRef } from "react"
import { MdWhatsapp } from "react-icons/md";
import { FaArrowRightLong } from "react-icons/fa6";

const cities = [
    { name: 'New York', code: 'NY' },
    { name: 'Rome', code: 'RM' },
    { name: 'London', code: 'LDN' },
    { name: 'Istanbul', code: 'IST' },
    { name: 'Paris', code: 'PRS' }
];

const LocationDetailPage = ({ BookingData,bookingBannerData }) => {

    console.log("bookingBannerData", bookingBannerData);
    const { bannerName, bannerImage } = bookingBannerData[0];
    console.log("bannerName", bannerName)
    console.log("bannerImage", bannerImage)

    const [selectedCities, setSelectedCities] = useState(null);
    const naviagte = useNavigate();

    const formRef = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm(
          "service_7rtuo0u",
          "template_0zel6ln",
          formRef.current,
          "5qBXGtbufOmEJ9GhA"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      e.target.reset();
    };

    const defalutValue = {
        FullName: '',
        EmailID: '',
        Phone: '',
        TravelData:'',
        NoofPax:'',
        Destination:'',
        YourMessage: '',

    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        FullName: yup.string().required('Full Name is Requird!'),
        EmailID: yup.string().required('Email is Requird!'),
        Phone: yup.string().required('Phone is Requird!'),
        TravelData: yup.string().required('Travel Data is Requird!'),
        NoofPax: yup.string().required('No of People is Requird!'),
        Destination: yup.string().required('Destination is Requird!'),
        YourMessage: yup.string().required('Your Message is Requird!'),

    })
    const handleSubmit = (value) => {
        console.log(value);

    }

    const toggleCategories = () => {
        var categoryList = document.getElementById('categoryList');
        categoryList.style.display = (categoryList.style.display === 'none') ? 'block' : 'none';
    }
    const toggleZone = () => {
        var zoneList = document.getElementById('zoneList');
        zoneList.style.display = (zoneList.style.display === 'none') ? 'block' : 'none';
    }
    const togglePrice = () => {
        var priceList = document.getElementById('priceList');
        priceList.style.display = (priceList.style.display === 'none') ? 'block' : 'none';
    }
    const toggleGender = () => {
        var genderList = document.getElementById('genderList');
        genderList.style.display = (genderList.style.display === 'none') ? 'block' : 'none';
    }
    const [showCount, setShowCount] = useState(12);
    const [showAll, setShowAll] = useState(false);

    const toggleImages = () => {
        if (!showAll) {
            const newShowCount = Math.min(showCount + 12, BalkansTourpackageData.length);
            setShowCount(newShowCount);
            if (newShowCount === BalkansTourpackageData.length) {
                setShowAll(true);
            }
        } else {
            setShowCount(showCount === 12 ? 3 : 12);
            setShowAll(false);
        }
    };

    // const viewDetailbtn = () => {
    //     naviagte(`/view_detail`)
    // }

    const handleContactClick = (phoneNumber) => {
        window.location.href = `whatsapp://send?phone=${encodeURIComponent(phoneNumber)}`;
    };

    return (
        <>
            <Header />


            <Box className='Hosp_locationdetail_topdiv'>
                <img src={bannerImage} alt="img" />
                <div className="hosp_locationdetailhead">
                    <h1>{bannerName}</h1>
                </div>
            </Box>

            <Box>

                <div className="celebrity-filtration-div">
                    <div className="leftsidediv col-sm-3">
                        <div className="filterdiv">
                            <h5>Filter</h5>
                            <h6><GiAnticlockwiseRotation />Reset</h6>
                        </div>

                        <div className="city-div">
                            <div onClick={toggleCategories} className="clickdiv">
                                <h6>Number of Nights</h6>
                                <IoMdArrowDropdown />
                            </div>
                            <ul id="categoryList" className="category-list">
                                <li >5 Night</li>
                                <li >3 Night</li>
                                <li >4 Night</li>
                                <li >6 Night</li>
                                <li >11 Night</li>
                                {/* <div className="card d-flex justify-content-center">
                                    <MultiSelect
                                        value={selectedCities}
                                        onChange={(e) => setSelectedCities(e.value)}
                                        options={cities}
                                        optionLabel="name"
                                        filter placeholder="Select Cities"
                                        maxSelectedLabels={3}
                                        className="w-full md:w-20rem"
                                    />
                                </div> */}
                                {/* {TopLocationData?.map((div) => (
                                    <li key={div}>{div.name}</li>
                                ))} */}
                            </ul>
                        </div>

                        <div className="city-div">
                            <div onClick={toggleZone} className="clickdiv">
                                <h6>Category</h6>
                                <IoMdArrowDropdown />
                            </div>
                            <ul id="zoneList" className="zone-list">
                                {CategoryData?.map((div) => (
                                    <li key={div}>{div.name}</li>
                                ))}
                            </ul>
                        </div>


                        <div className="city-div">
                            <div onClick={togglePrice} className="clickdiv">
                                <h6>Destination</h6>
                                <IoMdArrowDropdown />
                            </div>
                            <ul id="priceList" className="price-list">
                                <li >Dubai</li>
                                <li >Goa</li>
                                <li >Srinagar</li>
                                <li >Port Blair</li>
                                <li >Havelock Island</li>
                                <li >Manali</li>
                                <li >Shimla</li>
                                <li >Pahalgam</li>
                                <li >Gulmarg</li>
                                <li >Chandigarh</li>
                                <li >Paris</li>
                                <li >Pattaya</li>
                                <li >Bangkok</li>
                                <li >Kuala Lumpur</li>
                                <li >Singapore</li>

                                {/* {TopLocationData?.map((itm) => (
                                    <li key={itm}>{itm.name}</li>
                                ))} */}
                            </ul>
                        </div>



                        <div className="city-div">
                            <div onClick={toggleGender} className="clickdiv">
                                <h6>Price</h6>
                                <IoMdArrowDropdown />
                            </div>
                            <ul id="genderList" className="gender-list">
                                <li >Rs 10001-15000</li>
                                <li >Rs 15001-20000</li>
                                <li >Rs 20001-25000</li>
                                <li >Rs 25001-30000</li>
                                <li >Rs 95001-100000</li>

                                {/* {TopLocationData?.map((itm) => (
                                    <li key={itm}>{itm.name}</li>
                                ))} */}
                            </ul>
                        </div>

                    </div>
                    <div className="rightsidediv col-sm-9">
                        {BookingData?.map((item, index) => (

                            <div className="fixedDeparture_package">
                                <div className=" row">
                                    <div className=" col-lg-4 col-12">
                                        <img src={item.imgSrc} alt="img" />

                                    </div>
                                    <div className=" col-lg-8 col-12">
                                        <div className="detail_full">
                                            <div className="leftdivname">
                                                <h3>{item.tripName}</h3>
                                                <h5>{item.dayNightPack}</h5>
                                                <h4>{item.Destinations}</h4>

                                            </div>
                                            <div className="rightdivname">
                                                {/* <p>{'Departure : Mumbai'}</p>
                                                      <p>{'Category : Deluxe'}</p> */}
                                                <h2><span>Starting from</span> <br /> {item.price}</h2>
                                                <p>Per Person</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="boxbottom">
                                    <ul>
                                        <li>
                                            <img src={hotelimg} alt="img" />
                                            <p>Hotels</p>
                                        </li>
                                        <li>
                                            <img src={logimg} alt="img" />
                                            <p>Sightseeing</p>
                                        </li>
                                        <li>
                                            <img src={busimg} alt="img" />
                                            <p>Transfer</p>
                                        </li>
                                        <li>
                                            <img src={eatimg} alt="img" />
                                            <p>Meals</p>
                                        </li>
                                    </ul>
                                    <div>
                                        <Button className="btnn1"><Link to={`/${item.linkTag}`}> View Details <FaArrowRightLong className="ms-1 fs-6" /></Link></Button> 
                                        <Button onClick={() => handleContactClick('9871927829')} className="btnn2"><MdWhatsapp className="me-2 fs-4" />Books Now</Button>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className="heretoserve">
                            <h1>We are here to serve you in the best way</h1>
                            <p>We would like to understand your plans and come up with the right strategic advice for best tour package for you.</p>
                        </div>
                        <div className="card-body row justify-content-center align-items-center me-0 mt-4">
                            <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row justify-content-center me-0 p-0">
                                {({ errors, touched, resetForm }) => {
                                    return <Form ref={formRef} onSubmit={sendEmail} className="detailformdiv row justify-content-center align-items-center me-0">
                                        <div className="row me-0 justify-content-center align-items-center p-0">
                                           
                                            <div className="col-lg-6 col-12 mt-2">

                                                <Field type='text' name='FullName' placeholder='Full Name'
                                                    className={
                                                        `form-control
                                                    ${errors.FullName && touched.FullName ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="FullName" /></p>
                                            </div>



                                            <div className="col-lg-6 col-12 mt-2">

                                                <Field type='text' name='Phone' placeholder='Mobile Number'
                                                    className={
                                                        `form-control
                                                    ${errors.Phone && touched.Phone ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="Phone" /></p>
                                            </div>


                                            <div className=" col-lg-6 col-12 mt-2">

                                                <Field type='text' name='EmailID' placeholder='Email'
                                                    className={
                                                        `form-control
                                                ${errors.EmailID && touched.EmailID ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="EmailID" /></p>
                                            </div>
                                            
                                            <div className=" col-lg-6 col-12 mt-2">

                                                <Field type='date' name='TravelData' placeholder='TravelData'
                                                    className={
                                                        `form-control
                                                ${errors.TravelData && touched.TravelData ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="TravelData" /></p>
                                            </div>
                                            
                                            <div className=" col-lg-6 col-12 mt-2">

                                                <Field type='text' name='NoofPax' placeholder='No.of Pax'
                                                    className={
                                                        `form-control
                                                ${errors.NoofPax && touched.NoofPax ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="NoofPax" /></p>
                                            </div>

                                            <div className="col-lg-6  col-12 mt-2">

                                                <Field type='text' name='Destination' placeholder='Destination'
                                                    className={
                                                        `form-control
                                                ${errors.Destination && touched.Destination ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="Destination" /></p>
                                            </div>

                                            <div className=" mt-2">

                                                <Field as={'textarea'} rows={6} name='YourMessage' placeholder='Remarks'
                                                    className={
                                                        `form-control
                                                     ${errors.YourMessage && touched.YourMessage ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="YourMessage" /></p>
                                            </div>


                                            <div className="col-12 d-flex justify-content-end mt-3">
                                                <button type="submit" className="btnbtnbtn">
                                                    Send Enquiry
                                                </button>

                                            </div>
                                        </div>

                                    </Form>
                                }}
                            </Formik>
                        </div>
                    </div>

                </div>

            </Box>



            <Footer />

        </>
    )
}

export default LocationDetailPage