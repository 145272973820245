
import LocationDetailPage from "../../components/LocationDetailPage"
import InternationalSingaporeData, { InternationalSingaporeBannerData } from "../../data/tanishkaData/InternationalSingaporeData"


const InternationalSingapore = () => {
  return (
    <>
      <LocationDetailPage BookingData={InternationalSingaporeData} bookingBannerData={InternationalSingaporeBannerData} />
    </>
  )
}

export default InternationalSingapore