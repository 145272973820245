

import WestBengal1 from '../../assest/taniska/india/WestBengal/WestBengal1.png'
import WestBengal2 from '../../assest/taniska/india/WestBengal/WestBengal6.jpg'



export const DomesticWestBengalBannerData = [
    {
        bannerName: 'West Bengal',
        bannerImage: WestBengal1,
    },
];

const DomesticWestBengalData = [
    {
        id: 1,
        tripName: 'West Bengal Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'West Bengal, India',
        price: "₹20,671",
        imgSrc: WestBengal2,
        linkTag:'domestic_packages/west_bengal/west_bengal_detail',
    }

];
export default DomesticWestBengalData