
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react"
import { Box, Typography } from '@mui/material';
import '../../styles/faqStyle.css'
import Header from '../../common/TanishkaEnterprises/Header/Header';
import Footer from '../../common/TanishkaEnterprises/Footer/Footer';
import FaqData from '../../data/tanishkaData/FaqData';
import polygon from '../../assest/taniska/search-result-listing.jpg'


const FAQPage = () => {

    const [expandedAccordion, setExpandedAccordion] = useState(null);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpandedAccordion(isExpanded ? panel : null);
    };

    return (
        <>
            <Header />

            <Box className='Aboutus_topdiv'>
                <img src={polygon} alt="img" />
                <div className="hosp_aboutushead">
                    <h1>Frequently Asked Questions</h1>
                </div>
            </Box>

            <div className="maq_accordion">
                <h1 className=' fw-bold mt-4 mb-5 text-center fs-4'>Frequently Asked Questions</h1>
                {FaqData?.map((accordionItem) => (
                    <Accordion
                        className='maq_accordion-bigdiv'
                        key={accordionItem.id}
                        expanded={expandedAccordion === accordionItem.id}
                        onChange={handleAccordionChange(accordionItem.id)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${accordionItem.id}-content`}
                            id={`panel${accordionItem.id}-header`}
                        >
                            <Typography className="maq_bigdiv-ques">{accordionItem.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="maq_bigdiv-answr"> {accordionItem.details}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>

            <Footer />


        </>
    )
}

export default FAQPage