import ViewDetail from "../../components/ViewDetail"
import FixedDepartureDetailPageBannerData, { FixedDepartureDetailPageTextData } from "../../data/tanishkaData/FixedDepartureDetailPageData"

const FixedDepartureDetailPage = () => {
  return (
    <>
        <ViewDetail TourBannersData={FixedDepartureDetailPageBannerData} aboutTourInfo={FixedDepartureDetailPageTextData} />
    </>
  )
}

export default FixedDepartureDetailPage
