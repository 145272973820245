import { Box, Button } from "@mui/material"
import { Link } from "react-router-dom"
import Header from "../../common/TanishkaEnterprises/Header/Header"
import Footer from "../../common/TanishkaEnterprises/Footer/Footer"
import polygon from '../../assest/taniska/child.webp'
import '../../styles/TravelogueStyle.css'
import TravelogueData from "../../data/tanishkaData/TravelogueData"

const Travelogue = () => {
    return (
        <>
            <Header />

            <Box className='Travelogue_topdiv'>
                <img src={polygon} alt="img" />
                <div className="traveldiv_head">
                    <h1>Travelogue</h1>
                </div>
            </Box>

            <div className="Travelogue_div">
                <div className="travelogue_slide">
                    {TravelogueData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="travelogue_slidedetail">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h3>07 May 2024</h3>
                                <h4>{item.heading}</h4>
                                <p>Posted by : {item.Postedby}</p>
                                <p>{item.textdetail}</p>
                                <Button className="booknowbtn" fullWidth>Read More</Button>

                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="butnn">Explore More</Button> */}
            </div >
            <Footer />

        </>
    )
}

export default Travelogue