import Dubaiimg from '../../assest/taniska/dubai-marina.webp'
import dubai1 from '../../assest/taniska/dubai1.jpg'


export const InternationalDubaiBannerData = [
    {
        bannerName: 'Dubai',
        bannerImage: Dubaiimg,
    },
];

const InternationalDubaiData = [
    {
        id: 1,
        tripName: 'Dubai Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Palm Jumeirah, Dubai',
        price: "₹20,671",
        imgSrc: dubai1,
        linkTag:'international_packages/dubai/dubai_detail',
    },
   

];
export default InternationalDubaiData