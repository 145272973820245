
import ViewDetail from "../../components/ViewDetail"
import DomesticUttarakhandDetailBannerData, { DomesticUttarakhandTextData } from "../../data/tanishkaData/DomesticUttarakhandDetailData"

const DomesticUttarakhandDetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={DomesticUttarakhandDetailBannerData} aboutTourInfo={DomesticUttarakhandTextData} />
    </>
  )
}

export default DomesticUttarakhandDetail