import LocationDetailPage from "../../components/LocationDetailPage"
import DomesticAndamanData, { DomesticAndamanBannerData } from "../../data/tanishkaData/DomesticAndamanData"

const DomesticAndaman = () => {
  return (
    <>
      <LocationDetailPage BookingData={DomesticAndamanData} bookingBannerData={DomesticAndamanBannerData} />
    </>
  )
}

export default DomesticAndaman