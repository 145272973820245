

import people2 from '../../assest/taniska/fascinating-andaman.jpeg'
import people3 from '../../assest/taniska/himachal-highlights.jpeg'
import people5 from '../../assest/taniska/manali-shimla-escape.jpeg'
import people6 from '../../assest/taniska/paris.jpeg'
import people7 from '../../assest/taniska/the-best.jpeg'
import people1 from '../../assest/taniska/delightful-kashmi.jpeg'
import people8 from '../../assest/taniska/burjkhalifa.jpg'
import adventureimg from '../../assest/taniska/adventureimg.webp'




export const AdventureBannerData = [
    {
        bannerName: 'Adventure',
        bannerImage: adventureimg,
    },
];

const AdventureData = [
   
    {
        id: 1,
        tripName: 'Manali and Shimla Escape ',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Manali, Shimla',
        price: "₹20,671",
        imgSrc: people5,
        linkTag:'fixed_departure/adventure/adventure_detail',
    },
    {
        id: 2,
        tripName: 'The Best of Thailand, Malaysia, and Singapore ',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: ' Pattaya, Bangkok, Kuala Lumpur, Singapore',
        price: "₹99,971",
        imgSrc: people7,
        linkTag:'fixed_departure/adventure/adventure_detail',
    },
     {
        id: 3,
        tripName: 'Paris Delights ',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Paris',
        price: "₹50,671",
        imgSrc: people6,
        linkTag:'fixed_departure/adventure/adventure_detail',
    },
    {
        id: 4,
        tripName: 'Delightful Kashmir',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Srinagar',
        price: "₹20,671",
        imgSrc: people1,
        linkTag:'fixed_departure/adventure/adventure_detail',
    },
    {
        id: 5,
        tripName: 'Group Tour - Best of Dubai',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Dubai',
        price: "99,671",
        imgSrc: people8,
        linkTag:'fixed_departure/adventure/adventure_detail',
    },
    {
        id: 6,
        tripName: 'Fascinating Andaman',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Port Blair, Havelock Island',
        price: "₹20,671",
        imgSrc: people2,
        linkTag:'fixed_departure/adventure/adventure_detail',
    },
    {
        id: 7,
        tripName: 'HIMACHAL HIGHLIGHTS',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: ' Shimla, Manali, Chandigarh',
        price: "₹23,980",
        imgSrc: people3,
        linkTag:'fixed_departure/adventure/adventure_detail',
    },


];
export default AdventureData