
import srilanka1 from '../../assest/taniska/srilanka/srilanka4.jpg'
import srilanka2 from '../../assest/taniska/srilanka/srilanka2.png'




export const InternationalSriLankaBannerData = [
    {
        bannerName: 'Sri Lanka',
        bannerImage: srilanka1,
    },
];

const InternationalSriLankaData = [
    {
        id: 1,
        tripName: 'Sri Lanka Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Sri Lanka',
        price: "₹20,671",
        imgSrc: srilanka2,
        linkTag:'international_packages/sri_lanka/sri_lanka_detail',
    },
   

];
export default InternationalSriLankaData