import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './curveSliderStyle.css';
import { Link } from 'react-router-dom';


const CurveSlider = ({ title, detailsData }) => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '1px',
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '1px',
        },
      },
      {
        breakpoint: 9999, // A large value to ensure the last breakpoint always applies
        settings: {
          slidesToShow: 4,
        },
      }
    ],
  };

  return (
    <>
      <div className="internationalTour_slide">
        {/* <h1 className='fw-bold ms-3'>{title}</h1> */}
        <Slider {...settings}>
          {detailsData?.map((item, index) => (
            <Link to={`/international_packages/${item.linkTag}`}>
              <div key={item.id} className="Tour_life_Slider">
                <img src={item.imgSrc} alt={`img-${item.id}`} />
                <h4>{item.heading}</h4>
              </div>
            </Link>

          ))}
        </Slider >
      </div >
    </>
  )
}

export default CurveSlider
