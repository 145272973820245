
import people1 from '../../assest/taniska/balkans1.jpg.webp'
import people2 from '../../assest/taniska/balkans2.jpg.webp'
import people3 from '../../assest/taniska/balkans3.jpg.webp'
import people4 from '../../assest/taniska/balkans4.jpg.webp'
import people5 from '../../assest/taniska/balkans5.jpg.webp'
import people6 from '../../assest/taniska/balkans6.jpg.webp'
import people7 from '../../assest/taniska/balkans7.jpg.webp'
import people8 from '../../assest/taniska/balkans8.jpg.webp'



const BalkansTourpackageData = [
    {
        id: 1,
        heading:'Albania Tour Packages',
        textdetail:'Albania is a Southeastern Europe which is located on the Adriatic and…',
        imgSrc: people1,
    },
    {
        id: 2,
        heading:'Bosnia and Herzegovina Tours',
        textdetail:'When you scroll through the best sightseeing places in Europe, you…',
        imgSrc: people2,
    },
    {
        id: 3,
        heading:'Bulgaria Tour Packages',
        textdetail:'If you want to quench your wish to visit a culturally precious place…',
        imgSrc: people3,
    } ,
     {
        id: 4,
        heading:'Greece Tour Packages',
        textdetail:'Greece can be denoted as one of the most ancient yet mystic land for…',
        imgSrc: people4,
    },
     {
        id: 5,
        heading:'Kosovo Tour Packages ',
        textdetail:'Kosovo is one of the countries that has struggled hard for its…',
        imgSrc: people5,
    }, {
        id: 6,
        heading:'Macedonia Tour Packages ',
        textdetail:'Macedonia is a glorious country which was a part of erstwhile…',
        imgSrc: people6,
    }
    , {
        id: 7,
        heading:'Serbia Tour Packages ',
        textdetail:'Tanishka Tours has been customising best quality packages to Serbia since…',
        imgSrc: people7,
    }, {
        id: 8,
        heading:'Croatia Tour Packages ',
        textdetail:'Croatia is one of the most-sought after travel destinations in the…',
        imgSrc: people8,
    }
   
];
export default BalkansTourpackageData