import people1 from '../../assest/taniska/local1.jpg'
import people2 from '../../assest/taniska/local2.jpg'
import people3 from '../../assest/taniska/local3.webp'
import people4 from '../../assest/taniska/local4.jpg'
import people5 from '../../assest/taniska/local5.webp'



const LocalTourData = [
    {
        id: 1,
        heading:'Home Cooking Demonstration',
        imgSrc: people1,
    },
    {
        id: 2,
        heading:'Local village experiences',
        imgSrc: people2,
    },
    {
        id: 3,
        heading:'Elephant safari',
        imgSrc: people3,
    } ,
     {
        id: 4,
        heading:'Local art and craft',
        imgSrc: people4,
    },
     {
        id: 5,
        heading:'Jungle safari ',
        imgSrc: people5,
    }
   
];
export default LocalTourData