

import usa1 from '../../assest/taniska/usa.webp'
import usa2 from '../../assest/taniska/usa/California.webp'
import usa3 from '../../assest/taniska/usa/San-Francisco.jpg'
import usa4 from '../../assest/taniska/usa/Vancouver.jpg'





export const InternationalUSABannerData = [
    {
        bannerName: 'USA',
        bannerImage: usa3,
    },
];

const InternationalUSAData = [
    {
        id: 1,
        tripName: 'USA East Coast Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'USA',
        price: "₹20,671",
        imgSrc: usa1,
        linkTag:'international_packages/USA/USA_detail',
    },

    {
        id: 2,
        tripName: 'California Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'California, USA',
        price: "₹20,671",
        imgSrc: usa2,
        linkTag:'international_packages/USA/USA_detail',
    },
   
    {
        id: 3,
        tripName: 'San-Francisco Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'San-Francisco, USA',
        price: "₹20,671",
        imgSrc: usa3,
        linkTag:'international_packages/USA/USA_detail',
    },
    {
        id: 4,
        tripName: 'Vancouver Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Vancouver, USA',
        price: "₹20,671",
        imgSrc: usa4,
        linkTag:'international_packages/USA/USA_detail',
    },

];
export default InternationalUSAData