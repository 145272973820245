


import singalionimg from '../../assest/taniska/singalion.jpeg'


export const InternationalSingaporeBannerData = [
    {
        bannerName: 'Singapore',
        bannerImage: singalionimg,
    },
];

const InternationalSingaporeData = [
    {
        id: 1,
        tripName: 'Singapore Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Sentosa Island, Singapore',
        price: "₹20,671",
        imgSrc: singalionimg,
        linkTag:'international_packages/singapore/singapore_detail',
    },
   

];
export default InternationalSingaporeData