import ViewDetail from "../../components/ViewDetail"
import InternationalPackagesDetailPageBannerData, { InternationalPackagesDetailPageTextData } from "../../data/tanishkaData/InternationalPackagesDetailPageData"

const InternationalPackagesDetailPage = () => {
  return (
    <>
        <ViewDetail TourBannersData={InternationalPackagesDetailPageBannerData} aboutTourInfo={InternationalPackagesDetailPageTextData} />
    </>
  )
}

export default InternationalPackagesDetailPage
