
import ViewDetail from "../../components/ViewDetail"
import DomesticWestBengalDetailBannerData, { DomesticWestBengalTextData } from "../../data/tanishkaData/DomesticWestBengalDetailData"

const DomesticWestBengalDetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={DomesticWestBengalDetailBannerData} aboutTourInfo={DomesticWestBengalTextData} />
    </>
  )
}

export default DomesticWestBengalDetail