

import Nepal1 from '../../assest/taniska/Nepal/Nepal1.png'
import Nepal2 from '../../assest/taniska/Nepal/Nepal2.png'
import Nepal3 from '../../assest/taniska/Nepal/Nepal3.png'
import Nepal4 from '../../assest/taniska/Nepal/Nepal4.jpg'
import Nepal5 from '../../assest/taniska/Nepal/Nepal5.jpg'
import Nepal6 from '../../assest/taniska/Nepal/Nepal6.jpg'
import Nepal7 from '../../assest/taniska/Nepal/Nepal7.jpg'




const InternationalNepalDetailBannerData = [
    {
        id: 1,
        name: 'Nepal Tour',
        src: Nepal6,
    },
    {
        id: 2,
        name: 'Nepal Tour',
        src: Nepal2,
    },
    {
        id: 3,
        name: 'Nepal Tour',
        src: Nepal3,
    },
    {
        id: 4,
        name: 'Nepal Tour',
        src: Nepal4,
    },
    {
        id: 5,
        name: 'Nepal Tour',
        src: Nepal1,
    },
    {
        id: 6,
        name: 'Nepal Tour',
        src: Nepal5,
    },
    {
        id: 7,
        name: 'Nepal Tour',
        src: Nepal7,
    },
   
];
export default InternationalNepalDetailBannerData


export const InternationalNepalTextData = [
    {
      detailText: "Nepal is a very beautiful country, it is one of the landlocked country in South Asia. Nepal is well known for tours and travel. It is located in the Himalayas and is the 48th largest country in the world in terms of population. Kathmandu is the largest city as well as the capital city of Nepal country. Nepal is a multi-cultural country and Nepali is its official language. Hinduism is the predominant religion covering about 80% of the total population followed by Buddhism, Islam and then Christianity. The currency used in Nepal is Nepalese Rupee. Popular for mountaineering and has the tallest mountain 'The Mount Everest'. Nepal is a good country for nature lovers, trekkers and those who love sight-seeing. Travellers can find some of the highest and challenging mountains in the world over here. Nepal tourism has been one of the most favourite world tourism in past few years. The spectacular landscape, diverse and exotic cultures, beautiful mountains, ancient sculptures and holy temples are all contributing to the growth of the tourism industry in Nepal. Nepal, though been a poor country with fewer employment opportunities and high poverty level, has been constantly trying to overcome these problems and stand good in among the developing nations of the world. Nepalese people are very talented and some of the major exports of clothing, leather goods, grains, carpets and many other goods contribute to the economy of the country. Nepal tour packages allow you to have Nepal tourism, explore its culture, its beauty and everything worth visiting."
    }
];