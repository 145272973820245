
import ViewDetail from "../../components/ViewDetail"
import DomesticJammukashmirDetailBannerData, { DomesticJammukashmirTextData } from "../../data/tanishkaData/DomesticJammukashmirDetailData"

const DomesticJammukashmirDetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={DomesticJammukashmirDetailBannerData} aboutTourInfo={DomesticJammukashmirTextData} />
    </>
  )
}

export default DomesticJammukashmirDetail