import ViewDetail from "../../components/ViewDetail"
import InternationalSingaporeDetailBannerData, { InternationalSingaporeTextData } from "../../data/tanishkaData/InternationalSingaporeDetailData"

const InternationalSingaporeDetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={InternationalSingaporeDetailBannerData} aboutTourInfo={InternationalSingaporeTextData} />
    </>
  )
}

export default InternationalSingaporeDetail