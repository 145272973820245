import maldives1 from '../../assest/taniska/maldives/maldives1.png'
import maldives2 from '../../assest/taniska/maldives/maldives2.png'
import maldives3 from '../../assest/taniska/maldives/maldives3.png'
import maldives4 from '../../assest/taniska/maldives/maldives4.jpg'
import maldives5 from '../../assest/taniska/maldives/maldives5.jpg'
import maldives6 from '../../assest/taniska/maldives/maldives6.jpg'





const InternationalMaldivesDetailBannerData = [
    {
        id: 1,
        name: 'Maldives Tour',
        src: maldives2,
    },
    {
        id: 2,
        name: 'Maldives Tour',
        src: maldives3,
    },
    {
        id: 3,
        name: 'Maldives Tour',
        src: maldives1,
    },
    {
        id: 4,
        name: 'Maldives Tour',
        src: maldives4,
    },
    {
        id: 5,
        name: 'Maldives Tour',
        src: maldives5,
    },
    {
        id: 6,
        name: 'Maldives Tour',
        src: maldives6,
    },
];
export default InternationalMaldivesDetailBannerData


export const InternationalMaldivesTextData = [
    {
        detailText: "The Maldives, an island of luxury is blessed with incredible beauty and nature and is rich in high-end cuisine. Situated in the southwestern side of Sri Lanka and India, Male is the capital city of Maldives and is very famous among tourists. In around 1970s, Maldives was considered one in the list of the world’s 20 poorest countries. It was a middle-income country. However, Maldives has significantly made its space in the heart of the countries all over the world in the fishing and tourism industry. The tourism industry had begun in the 1980s and ever since then, the tourism industry has been flourishing on a great pace. All the islands in the Maldives country are low-lying with none of them having a height of more than 6 feet. As the country is a low-lying area, there is always a threat of global warming in the country. Tamilian and Sinhalese people from South India and Sri Lanka respectively were the first group of people that came and settled in Maldives. People from China, Indonesia and Arab countries visited Maldives in huge number centuries ago. Mostly, people speak English in this country and the official language spoken here is Dhivehi that is an Indo-European language also called Maldivian language. The religion dominant in this country is Islam. Sunni Muslims are found in huge numbers over here. Being surrounded by water everywhere, there are three modes of travelling between the islands in the Maldives that is possible. They are by flight, seaplane and boats. Velana International airport, Gan island airport and Hanimaadhoo airport are the three international airports that are popular in Maldives. Many tourist destinations attract travellers from all over the globe."
    }

];