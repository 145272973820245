
import Jammukashmir1 from '../../assest/taniska/india/Jammukashmir/Jammukashmir1.jpg'
import Jammukashmir2 from '../../assest/taniska/india/Jammukashmir/Jammukashmir2.jpg'



export const DomesticJammukashmirBannerData = [
    {
        bannerName: 'Jammu & kashmir',
        bannerImage: Jammukashmir2,
    },
];

const DomesticJammukashmirData = [
    {
        id: 1,
        tripName: 'Jammu & kashmir Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Jammu & kashmir, India',
        price: "₹20,671",
        imgSrc: Jammukashmir1,
        linkTag:'domestic_packages/Jammu_kashmir/Jammu_kashmir_detail',
    }

];
export default DomesticJammukashmirData