
import Footer from "../../common/TanishkaEnterprises/Footer/Footer"
import Header from "../../common/TanishkaEnterprises/Header/Header"
import polygon from '../../assest/taniska/search-result-listing.jpg'
import { Box } from "@mui/material"
import '../../styles/contactUsStyle.css'



const TermsOfUse = () => {

    return (
        <>
            <Header />

            <Box className='Aboutus_topdiv'>
                <img src={polygon} alt="img" />
                <div className="hosp_aboutushead">
                    <h1>Terms & Conditions</h1>
                </div>
            </Box>

            <div className=" bg-light-subtle p-5">
                <div className="">
                    <p className=" fw-bold">Terms & Conditions</p>
                    <p>The Website Owner, including subsidiaries and affiliates (“Website” or “Website Owner” or “we” or “us” or “our”) provides the information contained on the website or any of the pages comprising the website (“website”) to visitors (“visitors”) (cumulatively referred to as “you” or “your” hereinafter) subject to the terms and conditions set out in these website terms and conditions, the privacy policy and any other relevant terms and conditions, policies and notices which may be applicable to a specific section or module of the website.</p>
                    <p>Welcome to our website. If you continue to browse and use this website you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern Tanishka Tour's relationship with you in relation to this website.</p>
                    <p>The term 'Tanishka Tours' or 'us' or 'we' refers to the owner of the website whose registered office is Flat No 6 , Shankar Market , Connaught Place , New Delhi -110001 , India. The term 'you' refers to the user or viewer of our website.</p>
                    <p>The use of this website is subject to the following terms of use:</p>
                    <p>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</p>
                    <p>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</p>
                    <p>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.</p>
                    <p>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</p>
                    <p>All trade marks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website.</p>
                    <p>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offence.</p>
                    <p>From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</p>
                    <p>You may not create a link to this website from another website or document without [business name]'s prior written consent.</p>
                    <p>Your use of this website and any dispute arising out of such use of the website is subject to the laws of India or other regulatory authority.</p>
                    <p>"We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time"</p>
                    <p className=" fw-bold">Privacy Policy</p>
                    <p>A privacy policy states how you will respect the privacy of your website users. It says what information you will gather, how you will use it and how you will keep it secure.</p>
                    <p>A privacy policy is a legal document that discloses some or all of the ways a party gathers, uses, discloses and manages a customer's data. The exact contents of a privacy policy will depend upon the applicable law and may need to address the requirements of multiple countries or jurisdictions. While there is no universal guidance for the content of specific privacy policies, a number of organizations provide example forms.</p>
                    <p>Tanishka Tours  is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, and then you can be assured that it will only be used in accordance with this privacy statement.</p>
                    <p>Tanishka Tours may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This policy is effective from 01st January 2017.</p>
                    <p className=" fw-bold">What We Collect</p>
                    <p>We may collect the following information:</p>
                    <p>name and job title</p>
                    <p>contact information including email address</p>
                    <p>demographic information such as postcode, preferences and interests</p>
                    <p>other information relevant to customer surveys and/or offers</p>
                    <p className=" fw-bold">What we do with the information we gather</p>
                    <p>We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:</p>
                    <p>Internal record keeping.</p>
                    <p>We may use the information to improve our products and services.</p>
                    <p>We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</p>
                    <p>From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customise the website according to your interests.</p>

                    <p className=" fw-bold">Security</p>
                    <p>We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.</p>
                    <p className=" fw-bold">How we use cookies</p>
                    <p>A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyses web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.</p>
                    <p>We use traffic log cookies to identify which pages are being used. This helps us analyses data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.</p>
                    <p>Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.</p>
                    <p>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.</p>
                    <p className=" fw-bold">Controlling your personal information</p>
                    <p>You may choose to restrict the collection or use of your personal information in the following ways:</p>
                    <p>whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes</p>
                    <p>if you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at abaris@Tanishkatours.in</p>
                    <p>We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.</p>
                    <p className=" fw-bold">Disclaimer policy</p>
                    <p>A disclaimer may specify mutually-agreed and privately-arranged terms and conditions as part of a contract; or may specify warnings or expectations to the general public (or some other class of persons) in order to fulfill a duty of care owed to prevent unreasonable risk of harm or injury. Some disclaimers are intended to limit exposure to damages after harm or injury has already been suffered. Additionally, some kinds of disclaimers may represent a voluntary waiver of a right or obligation that may be owed to the disclaiming.</p>
                    <p>Disclaimers vary in terms of their uniformity. Some may vary depending on the specific context and parties involved, while other types of disclaimers may strictly adhere to a uniform and established set of formalities that are rarely or never modified, except under official authority.</p>

                    <p className=" fw-bold">Website disclaimer covers the following:</p>
                    <p>No warranties</p>
                    <p>Limitations of liability</p>
                    <p>Exceptions</p>
                    <p>Reasonableness</p>
                    <p>Other parties</p>
                    <p>Unenforceable provisions</p>
                    <p>This website disclaimer</p>

                    <p className=" fw-bold">Website disclaimer</p>
                    <p>The information contained in this website is for general information purposes only. The information is provided by [business name] and while we endeavor to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>
                    <p>In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arise out of, or in connection with, the use of this website.</p>
                    <p>Through this website you are able to link to other websites which are not under the control of [business name]. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.</p>
                    <p>Every effort is made to keep the website up and running smoothly. However, [business name] takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.</p>

                    <p className=" fw-bold">Cancellation & Refund Policy</p>
                    <p>Cancellation and Refund policy depends on the hotels and resorts where the reservation has been made , and it varies from hotel to a hotel and factors such as the seasonality , duration between the date of cancellation and the check in date , etc  . Tanishka Tours will charge a processing fee of 10% in case of cancellations / amendments .</p>
                    <p className=" fw-bold">BO Clause</p>
                    <p>We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time.</p>

                </div>
            </div>

            <Footer />

        </>
    )
}

export default TermsOfUse