
import LocationDetailPage from "../../components/LocationDetailPage"
import DomesticUttarakhandData, { DomesticUttarakhandBannerData } from "../../data/tanishkaData/DomesticUttarakhandData"

const DomesticUttarakhand = () => {
  return (
    <>
      <LocationDetailPage BookingData={DomesticUttarakhandData} bookingBannerData={DomesticUttarakhandBannerData} />
    </>
  )
}

export default DomesticUttarakhand