import LocationDetailPage from "../../components/LocationDetailPage"
import InternationalIndonesiaData, { InternationalIndonesiaBannerData } from "../../data/tanishkaData/InternationalIndonesiaData"


const InternationalIndonesia = () => {
  return (
    <>
      <LocationDetailPage BookingData={InternationalIndonesiaData} bookingBannerData={InternationalIndonesiaBannerData} />
    </>
  )
}

export default InternationalIndonesia