import LocationDetailPage from "../../components/LocationDetailPage"
import BeachData, { BeachBannerData } from "../../data/tanishkaData/BeachData"


const FixedDepartureBeach = () => {
  return (
    <>
      <LocationDetailPage BookingData={BeachData} bookingBannerData={BeachBannerData} />
    </>
  )
}

export default FixedDepartureBeach