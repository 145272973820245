
import ViewDetail from "../../components/ViewDetail"
import DomesticAndamanDetailBannerData, { DomesticAndamanTextData } from "../../data/tanishkaData/DomesticAndamanDetailData"

const DomesticAndamanDetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={DomesticAndamanDetailBannerData} aboutTourInfo={DomesticAndamanTextData} />
    </>
  )
}

export default DomesticAndamanDetail