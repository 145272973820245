import Footer from "../../common/TanishkaEnterprises/Footer/Footer"
import Header from "../../common/TanishkaEnterprises/Header/Header"
import polygon from '../../assest/taniska/search-result-listing.jpg'
import { Box } from "@mui/material"
import '../../styles/contactUsStyle.css'
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'
import { MdEmail, MdOutlinePunchClock, MdLocalPhone } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import emailjs from "@emailjs/browser";
import { useRef } from "react"


const myMail = [
    {
        recipient: 'sales@tanishkatravels.com',
        subject: '',
        body: ''
    }
];
const ContactUs = () => {

    const formRefrence = useRef();

    const sendToEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm(
          "service_7rtuo0u",
          "template_0zel6ln",
          formRefrence.current,
          "5qBXGtbufOmEJ9GhA"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      e.target.reset();
    };


    const defalutValue = {
        FullName: '',
        EmailID: '',
        Phone: '',
        YourMessage: '',

    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        FullName: yup.string().required('Name is Requird!'),
        EmailID: yup.string().required('Email is Requird!'),
        Phone: yup.string().required('Phone is Requird!'),
        YourMessage: yup.string().required('Your Message is Requird!'),

    })
    const handleSubmit = (value) => {
        console.log(value);

    }
    const dialNumber = () => {
        window.location.href = 'tel:+91 9871927829';
    };

    const emailClick = (recipient, subject, body) => {
        const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
    };
    return (
        <>
            <Header />

            <Box className='Contact_Hos_topdiv'>
                <img src={polygon} alt="img" />
                <div className="hosp_Contacthead">
                    <h1>Contact Us</h1>
                </div>
            </Box>

            <div className=" bg-danger-subtle p5tagdiv">
                <div className="agriLogistic_topdiv">
                    <h1 className=" fw-bold">Get In Touch</h1>
                </div>

                <div className="row justify-content-between">

                    <div className="col-lg-6 col-12 card-body row ggggg">
                        <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                            {({ errors, touched, resetForm }) => {
                                return <Form ref={formRefrence} onSubmit={sendToEmail} className="row justify-content-center formdiv">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6 col-12 mt-2">

                                            <Field type='text' name='FullName' placeholder='Name'
                                                className={
                                                    `form-control
                                                    ${errors.FullName && touched.FullName ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="FullName" /></p>
                                        </div>



                                        <div className="col-lg-6 col-12 mt-2">

                                            <Field type='text' name='Phone' placeholder='Mobile Number'
                                                className={
                                                    `form-control
                                                    ${errors.Phone && touched.Phone ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="Phone" /></p>
                                        </div>


                                        <div className=" mt-2">

                                            <Field type='text' name='EmailID' placeholder='Email'
                                                className={
                                                    `form-control
                                                ${errors.EmailID && touched.EmailID ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="EmailID" /></p>
                                        </div>

                                        <div className=" mt-2">

                                            <Field as={'textarea'} rows={6} name='YourMessage' placeholder='Your Message'
                                                className={
                                                    `form-control
                                                     ${errors.YourMessage && touched.YourMessage ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="YourMessage" /></p>
                                        </div>


                                        <div className="col-12 d-flex justify-content-center mt-2">
                                            <button type="submit" className="btnbtnbtn">
                                                Submit Form
                                            </button>

                                        </div>
                                    </div>

                                </Form>
                            }}
                        </Formik>
                    </div>

                    <div className=" col-lg-5 col-12 TouchContact ">
                        <div className="contactdiv">
                            <div className="ngo_icondiv">
                                <p> <IoLocationSharp className="icons" /></p>
                            </div>
                            <div className="ngocontact">
                                <p>
                                    <strong>Address : </strong> RZ-24, C Block, Gali no. 5, Durga Vihar Phase-2, Dindarpur Najafgarh, New Delhi-110043, India
                                </p>
                            </div>

                        </div>

                        <div className="contactdiv">
                            <div className="ngo_icondiv">
                                <p> <MdLocalPhone className="icons" /></p>
                            </div>
                            <div className="ngocontact">
                                <p onClick={dialNumber}> <strong>Phone : </strong>+91 9871927829</p>
                            </div>

                        </div>

                        <div className="contactdiv">
                            <div className="ngo_icondiv">
                                <p> <MdEmail className="icons" /></p>
                            </div>
                            <div className="ngocontact">
                                {myMail.map((email, index) => (
                                    <p key={index}
                                        onClick={() => emailClick(email.recipient, email.subject, email.body)}
                                    >
                                        <strong>Email ID : </strong> {email.recipient}
                                    </p>
                                ))}
                            </div>

                        </div>

                        {/* <div className="contactdiv">
                            <div className="ngo_icondiv">
                                <p> <MdOutlinePunchClock className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p> <strong>Office Hours : </strong> 10 AM To 6 PM Mon-Sat</p>
                            </div>

                        </div> */}
                    </div>

                </div>
            </div>


            <Footer />

        </>
    )
}

export default ContactUs