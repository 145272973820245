
import Bhutan from '../../assest/taniska/Bhutan-Tour.jpg'
import Bhutan1 from '../../assest/taniska/Bhutan/Bhutan3.png'




export const InternationalBhutanBannerData = [
    {
        bannerName: 'Bhutan',
        bannerImage: Bhutan1,
    },
];

const InternationalBhutanData = [
    {
        id: 1,
        tripName: 'Bhutan Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Bhutan',
        price: "₹20,671",
        imgSrc: Bhutan,
        linkTag:'international_packages/bhutan/bhutan_detail',
    },
   

];
export default InternationalBhutanData