
import LocationDetailPage from "../../components/LocationDetailPage"
import InternationalThailandData, { InternationalThailandBannerData } from "../../data/tanishkaData/InternationalThailandData"


const InternationalThailand = () => {
  return (
    <>
      <LocationDetailPage BookingData={InternationalThailandData} bookingBannerData={InternationalThailandBannerData} />
    </>
  )
}

export default InternationalThailand