import ViewDetail from "../../components/ViewDetail"
import InternationalNepalDetailBannerData, { InternationalNepalTextData } from "../../data/tanishkaData/InternationalNepalDetailData"

const InternationalNepalDetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={InternationalNepalDetailBannerData} aboutTourInfo={InternationalNepalTextData} />
    </>
  )
}

export default InternationalNepalDetail