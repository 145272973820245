


import burjkhalifa from '../../assest/taniska/burjkhalifa.jpg'
import dubai1 from '../../assest/taniska/dubai1.jpg'
import dubai2 from '../../assest/taniska/dubai2.jpg'
import dubai3 from '../../assest/taniska/dubai3.jpg'
import dubai4 from '../../assest/taniska/dubai4.jpg'




const InternationalDubaiDetailBannerData = [
    {
        id: 1,
        name: 'Dubai Tour',
        src: dubai1,
    },
    {
        id: 2,
        name: 'Dubai Tour',
        src: dubai2,
    },
    {
        id: 3,
        name: 'Dubai Tour',
        src: dubai3,
    },
    {
        id: 4,
        name: 'Dubai Tour',
        src: dubai4,
    }
    ,
    {
        id: 5,
        name: 'Dubai Tour',
        src: burjkhalifa,
    }
  
   

];
export default InternationalDubaiDetailBannerData


export const InternationalDubaiTextData = [
    {
        detailText: "Dubai, the must-visit place once in a lifetime, it does not stop surprising one with all its irresistible charm. A city that leaves no stone unturned to keep attracting people from the corners of the world, is rich in everything. Be it the culture, historical monuments, sightseeing, palmy beaches, nightlife, walking on streets having tall and beautiful building constructions aside, entertainment, exciting events, Dubai has everything to offer what you ask for. Dubai is said to be a global city situated on the Persian Gulf coast of the U.A.E. sharing its border with the Abu Dhabi in the South. Being the capital and the second-largest city of the seven United Arab Emirates, Dubai is well versed in its population having about 2.1 million inhabitants. The city has some biggest and largest constructions in the world continues to follow its ethnicity and purity and preserves some of the most historical attractions. Dubai is said to have blessed with golden sunshine and silvery beaches. Dubai is a city that is actively in charge of writing its destiny having grand projects lined up such as flying taxis and hyperloop trains and advanced technologies easing day to day life of people. Dubai tour packages from Abaris Travels can help you explore all of it in once providing a life-time memorable experience."
    }

];