
import Delhi1 from '../../assest/taniska/india/Delhi/Delhi0.jpg'
import Delhi2 from '../../assest/taniska/india/Delhi/Delhi6.webp'



export const DomesticDelhiBannerData = [
    {
        bannerName: 'Delhi',
        bannerImage: Delhi1,
    },
];

const DomesticDelhiData = [
    {
        id: 1,
        tripName: 'Delhi Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Delhi, India',
        price: "₹20,671",
        imgSrc: Delhi2,
        linkTag:'domestic_packages/delhi/delhi_detail',
    }

];
export default DomesticDelhiData