import ViewDetail from "../../components/ViewDetail"
import DomesticHimachalPradeshDetailBannerData, { DomesticHimachalPradeshTextData } from "../../data/tanishkaData/DomesticHimachalPradeshDetailData"

const DomesticHimachalPradeshDetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={DomesticHimachalPradeshDetailBannerData} aboutTourInfo={DomesticHimachalPradeshTextData} />
    </>
  )
}

export default DomesticHimachalPradeshDetail