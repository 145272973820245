import LocationDetailPage from "../../components/LocationDetailPage"
import DomesticHimachalPradeshData, { DomesticHimachalPradeshBannerData } from "../../data/tanishkaData/DomesticHimachalPradeshData"

const DomesticHimachalPradesh = () => {
  return (
    <>
      <LocationDetailPage BookingData={DomesticHimachalPradeshData} bookingBannerData={DomesticHimachalPradeshBannerData} />
    </>
  )
}

export default DomesticHimachalPradesh