
import banner1 from '../../assest/taniska/banner1.webp'
import banner2 from '../../assest/taniska/banner2.webp'
import banner3 from '../../assest/taniska/banner3.webp'
import banner4 from '../../assest/taniska/banner4.webp'
import banner5 from '../../assest/taniska/banner5.webp'


const CategoryData = [
    {
        id: 1,
        imgSrc: banner2,
        name:'Honeymoon'
    },
    {
        id: 2,
        imgSrc: banner3,
        name:'Family'
    },
    {
        id: 3,
        imgSrc: banner4,
        name:'Shopping and Nightlife'

    }, 
    {
        id: 4,
        imgSrc: banner5,
        name:'Leisure'
    }, 
    {
        id: 5,
        imgSrc: banner1,
        name:'Group Tours'

    }
    
];
export default CategoryData