
import LocationDetailPage from "../../components/LocationDetailPage"
import InternationalDubaiData, { InternationalDubaiBannerData } from "../../data/tanishkaData/InternationalDubaiData"


const InternationalDubai = () => {
  return (
    <>
      <LocationDetailPage BookingData={InternationalDubaiData} bookingBannerData={InternationalDubaiBannerData} />
    </>
  )
}

export default InternationalDubai