import ViewDetail from "../../components/ViewDetail"
import InternationalMaldivesDetailBannerData, { InternationalMaldivesTextData } from "../../data/tanishkaData/InternationalMaldivesDetailData"

const InternationalMaldivesDetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={InternationalMaldivesDetailBannerData} aboutTourInfo={InternationalMaldivesTextData} />
    </>
  )
}

export default InternationalMaldivesDetail