

import ViewDetail from "../../components/ViewDetail"
import DomesticTamilNaduDetailBannerData, { DomesticTamilNaduTextData } from "../../data/tanishkaData/DomesticTamilNaduDetailData"

const DomesticTamilNaduDetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={DomesticTamilNaduDetailBannerData} aboutTourInfo={DomesticTamilNaduTextData} />
    </>
  )
}

export default DomesticTamilNaduDetail