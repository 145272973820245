import LocationDetailPage from "../../components/LocationDetailPage"
import DomesticDelhiData, { DomesticDelhiBannerData } from "../../data/tanishkaData/DomesticDelhiData"

const DomesticDelhi = () => {
  return (
    <>
      <LocationDetailPage BookingData={DomesticDelhiData} bookingBannerData={DomesticDelhiBannerData} />
    </>
  )
}

export default DomesticDelhi