
import ViewDetail from "../../components/ViewDetail"
import WildLifeDetailPageBannerData, { WildLifeDetailPageTextData } from "../../data/tanishkaData/WildLifeDetailPageData"

const WildLifeDetailPage = () => {
  return (
    <>
        <ViewDetail TourBannersData={WildLifeDetailPageBannerData} aboutTourInfo={WildLifeDetailPageTextData} />
    </>
  )
}

export default WildLifeDetailPage