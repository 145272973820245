
import LocationDetailPage from "../../components/LocationDetailPage"
import InternationalVietnamData, { InternationalVietnamBannerData } from "../../data/tanishkaData/InternationalVietnamData"

const InternationalVietnam = () => {
  return (
    <>
      <LocationDetailPage BookingData={InternationalVietnamData} bookingBannerData={InternationalVietnamBannerData} />
    </>
  )
}

export default InternationalVietnam