
import ViewDetail from "../../components/ViewDetail"
import DomesticDelhiDetailBannerData, { DomesticDelhiTextData } from "../../data/tanishkaData/DomesticDelhiDetailData"

const DomesticDelhiDetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={DomesticDelhiDetailBannerData} aboutTourInfo={DomesticDelhiTextData} />
    </>
  )
}

export default DomesticDelhiDetail