

import ViewDetail from "../../components/ViewDetail"
import DomesticKeralaDetailBannerData, { DomesticKeralaTextData } from "../../data/tanishkaData/DomesticKeralaDetailData"

const DomesticKeralaDetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={DomesticKeralaDetailBannerData} aboutTourInfo={DomesticKeralaTextData} />
    </>
  )
}

export default DomesticKeralaDetail