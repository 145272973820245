
import europe from '../../assest/taniska/europe.webp'
import europe1 from '../../assest/taniska/europe/Budapest Hungary.jpg'
import europe2 from '../../assest/taniska/europe/Monte Isola Italy.jpg'
import europe3 from '../../assest/taniska/europe/Switzerland.png'
import europe4 from '../../assest/taniska/europe/Munich.jpeg'
import europe5 from '../../assest/taniska/europe/ParisFrance.jpg'


export const InternationalEuropeBannerData = [
    {
        bannerName: 'Europe',
        bannerImage: europe,
    },
];

const InternationalEuropeData = [
    {
        id: 1,
        tripName: 'Hungary Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Budapest, Hungary',
        price: "₹20,671",
        imgSrc: europe1,
        linkTag:'international_packages/europe/europe_detail',
    },

    {
        id: 2,
        tripName: 'Switzerland Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Switzerland',
        price: "₹20,671",
        imgSrc: europe3,
        linkTag:'international_packages/europe/europe_detail',
    },
    {
        id: 3,
        tripName: 'Italy Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Monte Isola, Italy',
        price: "₹20,671",
        imgSrc: europe2,
        linkTag:'international_packages/europe/europe_detail',
    },
    {
        id: 4,
        tripName: 'Germany Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Munich, Germany',
        price: "₹20,671",
        imgSrc: europe4,
        linkTag:'international_packages/europe/europe_detail',
    },
    {
        id: 5,
        tripName: 'France Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Paris, France',
        price: "₹20,671",
        imgSrc: europe5,
        linkTag:'international_packages/europe/europe_detail',
    },
   

];
export default InternationalEuropeData