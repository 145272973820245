
import ViewDetail from "../../components/ViewDetail"
import InternationalIndonesiaDetailBannerData, { InternationalIndonesiaTextData } from "../../data/tanishkaData/InternationalIndonesiaDetailData"

const InternationalIndonesiaDetail = () => {
  return (
    <>
        <ViewDetail TourBannersData={InternationalIndonesiaDetailBannerData} aboutTourInfo={InternationalIndonesiaTextData} />
    </>
  )
}

export default InternationalIndonesiaDetail