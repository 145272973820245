
import LocationDetailPage from "../../components/LocationDetailPage"
import DomesticJammukashmirData, { DomesticJammukashmirBannerData } from "../../data/tanishkaData/DomesticJammukashmirData"

const DomesticJammukashmir = () => {
  return (
    <>
      <LocationDetailPage BookingData={DomesticJammukashmirData} bookingBannerData={DomesticJammukashmirBannerData} />
    </>
  )
}

export default DomesticJammukashmir