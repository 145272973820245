import people1 from '../../assest/taniska/balikutabeach.jpg'
import people2 from '../../assest/taniska/Andamanbeach.jpg'
import people3 from '../../assest/taniska/goabeach.jpg'
import people4 from '../../assest/taniska/fascinating-andaman.jpeg'
import people5 from '../../assest/taniska/the-best.jpeg'
import people6 from '../../assest/taniska/burjkhalifa.jpg'
import people7 from '../../assest/taniska/himachal-highlights.jpeg'
import people8 from '../../assest/taniska/manali-shimla-escape.jpeg'
import people9 from '../../assest/taniska/paris.jpeg'
import people10 from '../../assest/taniska/delightful-kashmi.jpeg'
import worldtourimg from '../../assest/taniska/worldtourimg.jpg'




const FixedDepartureDetailPageBannerData = [
    {
        id: 1,
        name: 'Fixed Departure Tour',
        src: people1,
    },
    {
        id: 2,
        name: 'Fixed Departure Tour',
        src: people2,
    },
    {
        id: 3,
        name: 'Fixed Departure Tour',
        src: people3,
    },
    {
        id: 5,
        name: 'Fixed Departure Tour',
        src: people5,
    },
    {
        id: 6,
        name: 'Fixed Departure Tour',
        src: people6,
    },
    {
        id: 7,
        name: 'Fixed Departure Tour',
        src: people7,
    },
    {
        id: 8,
        name: 'Fixed Departure Tour',
        src: people8,
    }
    ,
    {
        id: 9,
        name: 'Fixed Departure Tour',
        src: people9,
    }
    ,
    {
        id: 10,
        name: 'Fixed Departure Tour',
        src: people10,
    }
    ,
    {
        id: 9,
        name: 'Fixed Departure Tour',
        src: worldtourimg,
    }

];
export default FixedDepartureDetailPageBannerData


export const FixedDepartureDetailPageTextData = [
    {
        detailText: "Fixed departure tours are pre-planned and pre-scheduled tours where a group of travelers join a tour package that has a fixed departure date, itinerary, and cost. These tours are designed for travelers who want to experience a destination without the hassle of planning and organizing everything themselves. With The Indian Tanishka fixed departure tours, all the arrangements such as accommodations, transport, meals, and sightseeing are pre-booked and included in the package cost. The travelers have to pay a fixed price for the entire tour, and everything is taken care of by the Indian Tanishka. These fixed departure tours are curated by destination experts with over 10 years of experience. These are an excellent option for solo travelers or small groups who want to travel with like-minded people. These tours are designed for wildlife enthusiasts who want to experience the beauty and diversity of wildlife. The tours are usually led by experienced naturalists or wildlife guides who are knowledgeable about the local flora and fauna. They provide insights into the behavior and ecology of the animals and help the travelers to spot and identify them in their natural habitats. Have a glance at our finely curated Fixed departure tours."
    }

];