
import ViewDetail from "../../components/ViewDetail"
import DomesticPackagesDetailPageBannerData, { DomesticPackagesDetailPageTextData } from "../../data/tanishkaData/DomesticPackagesDetailPageData"

const DomesticPackagesDetailPage = () => {
  return (
    <>
        <ViewDetail TourBannersData={DomesticPackagesDetailPageBannerData} aboutTourInfo={DomesticPackagesDetailPageTextData} />
    </>
  )
}

export default DomesticPackagesDetailPage
